﻿@import "../config";

.m-basket-overlay
{
	margin: 0;
	padding: 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__menu
	{
		margin: 0;
		padding: 0;
		&__item
		{
			border-top: 1px solid $colorDivider;
			margin: 15px 0 0 0;
			padding: 15px 0 0 0;
			&:first-child
			{
				border-top: 0;
				margin-top: 0;
				padding-top: 0;
			}
		}
	}
	&__item
	{
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
		&__image
		{
			border: 1px solid $colorDivider;
			float: left;
			margin: 0 15px 0 0;
			padding: 0;
			width: 98px;
		}
		&__content
		{
			margin: 0;
			overflow: hidden;
			padding: 0;
		}
		&__title
		{
			@include font-size($fontSize);
			font-family: $fontBold;
			@include line-height($fontHeight);
			margin: 0;
			padding: 0;
		}
		&__price
		{
			color: $colorGreyMid;
			font-size: 0;
			line-height: 0;
			margin: 0 0 0 -10px;
			padding: 5px 0 0 0;
			&__list, &__other
			{
				display: inline-block;
				@include font-size($fontSize - 2);
				@include line-height($fontHeight - 2);
				margin: 0;
				padding: 0 0 0 10px;
			}
			&__list
			{
				color: $colorPrimary;
				font-family: $fontBold;
				@include font-size($fontSize + 2);
				font-weight: normal;
				@include line-height($fontHeight + 2);
        &--sale
        {
          color: $colorRed;
        }
			}
			&__other
			{
				&--consumer
				{
					display: block;
				}
			}
		}
	}
	&--spaced
	{
		padding-bottom: 15px;
		@include mq-min($bpTabletLarge)
		{
			padding-bottom: 20px;
		}
		@include mq-min($bpDesktop)
		{
			padding-bottom: 25px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-bottom: 30px;
		}
	}
}