﻿@import "../config";

.l-breadcrumbs
{
	margin: 0;
	padding: 0 0 15px 0;
	text-transform: uppercase;
	&__inner
	{
		margin: 0 auto;
		padding: 0;
		text-align: left;
		max-width: $maxPageWidth;
	}
	&__menu
	{
		color: $colorGreyMid;
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize - 3);
			@include line-height($fontHeight - 3);
			margin: 0;
			padding: 0 6px 6px 0;
			position: relative;
			vertical-align: top;
			z-index: 1;
			&:after
			{
				color: $colorGreyLight;
				content: "/";
				letter-spacing: -1px;
				margin: 0;
				padding: 0 0 0 2px;
				@include mq-min($bpTabletLarge)
				{
					padding-left: 4px;
				}
			}
			&:last-child
			{
				padding-right: 0;
				&:after
				{
					display: none;
				}
			}
			@include mq-min($bpTabletLarge)
			{
				padding-right: 8px;
			}
			> span
			{
				display:inline-block;
				padding: 5px 0;
			}
		}
		&__link
		{
			color: $colorPrimary;
			text-decoration: none;
			padding: 5px 0;
			&:hover
			{
				color: $colorPrimary;
				text-decoration: underline;
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 30px;
	}
	@include mq-min($bpLarge)
	{
		padding-bottom: 35px;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}