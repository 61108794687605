﻿@import "../config";

.m-image-block
{
	background: $colorBase;
	color: $colorWhite;
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 1;
	&:before
	{
		background: rgba($colorWhite, .15);
		bottom: 0;
		content: "";
		display: none;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
	}
	&__image, &__faux-image
	{
		display: block;
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
	}
	&__faux-image
	{
		padding-top: 56.25%;
		&:before
		{
			color: $colorGreyMid;
			content: "No image available";
			font-size: 12px;
			left: 15px;
			line-height: 12px;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 15px;
			top: 50%;
			text-align: center;
			@include transform(translateY(-50%));
			z-index: 1;
		}
	}
	&__content
	{
		align-items: center;
		bottom: 15px;
		display: flex;
		left: 15px;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		right: 15px;
		z-index: 3;
	}
	&__icon
	{
		float: left;
		margin: 0 5px 0 0;
		padding: 0;
	}
	&__title
	{
		@include font-size($fontSize + 6);
		@include line-height($fontHeight + 6);
		margin: 0;
		overflow: hidden;
		padding: 0;
		vertical-align: middle;
		@include mq-min($bpTablet)
		{
			@include font-size($fontSize + 2);
			@include line-height($fontHeight + 2);
		}
		@include mq-min($bpDesktop)
		{
			@include font-size($fontSize + 6);
			@include line-height($fontHeight + 6);
		}
	}
	&__link
	{
		bottom: 0;
		display: block;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		@include text-hidden();
		top: 0;
		z-index: 4;
	}
	&:hover
	{
		&:before
		{
			.no-touchevents &
			{
				display: block;
			}
		}
	}
}