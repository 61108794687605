@import "../config";

#popupFade
{
	background: $colorBlack;
	background: rgba($colorBlack, .5);
	display: none;
	height: 100%;
	left: 0;
	margin: 0;
	padding: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9990;
}

.m-popup {
	background: $colorWhite;
	@include box-shadow(0 0 20px 0 rgba($colorBlack, .25));
	display: none;
	left: 50%;
	margin: 0 0 0 -300px;
	padding: 0;
	position: absolute;
	top: 50px;
	width: 600px;
	z-index: 9999;

	&__title {
		font-family: $fontBold;
		@include font-size($fontSize + 14);
		font-weight: normal;
		@include line-height($fontSize + 14);
		margin: 0;
		padding: 0 0 15px 0;

		@include mq-min($bpTabletLarge) {
			padding-bottom: 20px;
		}

		@include mq-min($bpDesktop) {
			padding-bottom: 25px;
		}

		@include mq-min($bpDesktopLarge) {
			padding-bottom: 30px;
		}
	}

	&__inner {
		@include min-height(100px);
		margin: 0;
		padding: 15px 15px 20px 15px;
		text-align: left;

		@include mq-min($bpDesktop) {
			padding: 20px 20px 25px 20px;
		}

		@include mq-min($bpDesktopLarge) {
			padding: 25px 25px 30px 25px;
		}

		@include mq-min($bpLarge) {
			padding: 30px 30px 35px 30px;
		}
	}

	&__content {
		&--max {
			max-height: 800px;
			overflow-x: auto;
			padding-bottom: 10px;
		}
	}

	&__video {
		background: $colorBlack;
		height: 0;
		margin: 0 auto;
		overflow: hidden;
		padding: 35px 0 56.25% 0;
		position: relative;
		z-index: 1;

		iframe {
			display: block;
			height: 100%;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
		}
	}

	&__gallery {
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;

		&__inner {
			margin: 0;
			padding: 0;
		}

		&__menu {
			margin: 0;
			padding: 0;

			&__item {
				display: none;
				margin: 0;
				padding: 0;

				&:first-child {
					display: block;
				}
			}
		}
	}

	&__actions {
		clear: both;
		font-size: 0;
		line-height: 0;
		margin: -10px 0 0 -10px;
		padding: 15px 0 0 0;

		&__item {
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize);
			@include line-height($fontHeight);
			margin: 0;
			padding: 10px 0 0 10px;
			vertical-align: top;
		}

		@include mq-min($bpTabletLarge) {
			padding-top: 20px;
		}

		@include mq-min($bpDesktop) {
			padding-top: 25px;
		}

		@include mq-min($bpDesktopLarge) {
			padding-top: 30px;
		}
	}

	&__close {
		background: $colorBase;
		border: 2px solid $colorWhite;
		@include border-radius(100%);
		@include box-shadow(0 0 5px 0 rgba($colorBlack, .5));
		display: block;
		height: 22px;
		margin: 0;
		padding: 0;
		position: absolute;
		right: -10px;
		@include text-hidden();
		top: -10px;
		width: 22px;
		z-index: 1;

		&:before {
			@include bg('/images/camc/common/sprite.png', no-repeat, -240px -4px);
			content: "";
			display: block;
			height: 14px;
			left: 50%;
			margin: -6px 0 0 -7px;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 14px;
			z-index: 1;
		}

		&:hover {
			background: $colorPrimary;
		}
	}

	@include mq-min($bpTabletLarge) {
		margin-left: -350px;
		width: 700px;
	}

	@include mq-min($bpDesktop) {
		margin-left: -450px;
		width: 900px;
	}

	@include mq-min($bpDesktopLarge) {
		margin-left: -510px;
		width: 1020px;
	}

	&--video {
		&__inner {
			padding: 10px;
		}
	}

	&--gallery {
		left: 15px;
		margin-left: 0;
		right: 15px;
		width: auto;

		@include mq-min($bpTabletLarge) {
			left: 50%;
			margin-left: -370px;
			right: auto;
			width: 740px;
		}

		@include mq-min($bpDesktop) {
			margin-left: -400px;
			width: 800px;
		}

		@include mq-min($bpDesktopLarge) {
			margin-left: -450px;
			width: 900px;
		}
	}

	&--product-preview {
		left: 15px;
		margin-left: 0;
		right: 15px;
		width: auto;

		@include mq-min($bpTabletLarge) {
			left: 50%;
			margin-left: -370px;
			right: auto;
			width: 740px;
		}

		@include mq-min($bpDesktop) {
			margin-left: -400px;
			width: 800px;
		}

		@include mq-min($bpDesktopLarge) {
			margin-left: -450px;
			width: 900px;
		}
	}

	&--basket-overlay {
		left: 15px;
		margin-left: 0;
		right: 15px;
		width: auto;

		@include mq-min($bpTabletLarge) {
			left: 50%;
			margin-left: -370px;
			right: auto;
			width: 740px;
		}
	}

	&--small {
		left: 15px;
		margin-left: 0;
		right: 15px;
		width: auto;

		@include mq-min($bpMobileLarge) {
			left: 50%;
			margin-left: -200px;
			right: auto;
			width: 400px;
		}

		@include mq-min($bpTablet) {
			margin-left: -250px;
			width: 500px;
		}
	}

	&--medium {
		left: 15px;
		margin-left: 0;
		right: 15px;
		width: auto;

		@include mq-min($bpTablet) {
			left: 50%;
			margin-left: -275px;
			right: auto;
			width: 550px;
		}

		@include mq-min($bpTabletLarge) {
			margin-left: -300px;
			width: 600px;
		}

		@include mq-min($bpDesktop) {
			margin-left: -325px;
			width: 650px;
		}

		@include mq-min($bpDesktopLarge) {
			margin-left: -350px;
			width: 700px;
		}
	}
}

#popupLoader {
	@include bg('/images/camc/common/loader.gif', no-repeat, 50% 50%);
	background-color: $colorWhite;
	display: none;
	height: 40px;
	left: 50%;
	margin: -20px 0 0 -20px;
	padding: 0;
	position: fixed;
	top: 50%;
	width: 40px;
	z-index: 9998;
}
