﻿@import "../config";

.m-checkout-actions
{
	margin: 0;
	padding: 15px 0;
	&__inner
	{
		border-top: 1px solid $colorDivider;
		margin: 0;
		padding: 15px 0;
		@include mq-min($bpTabletLarge)
		{
			padding: 20px 0;
		}
		@include mq-min($bpDesktop)
		{
			padding: 25px 0;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 30px 0;
		}
		&--no-divider
		{
			border-top: 0;
			padding-top: 0;
		}
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: -15px 0 0 -15px;
		padding: 0;
		text-align: left;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 15px 0 0 15px;
			vertical-align: middle;
			@include mq-min($bpTabletLarge)
			{
				padding: 20px 0 0 20px;
			}
			@include mq-min($bpDesktop)
			{
				padding: 25px 0 0 25px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding: 30px 0 0 30px;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			margin: -20px 0 0 -20px;
		}
		@include mq-min($bpDesktop)
		{
			margin: -25px 0 0 -25px;
		}
		@include mq-min($bpDesktopLarge)
		{
			margin: -30px 0 0 -30px;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-top: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-top: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-top: 30px;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}