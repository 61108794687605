@import "../config";

.m-print-link {
	color: $colorBase;
	display: inline-block;
	font-family: $fontBold;
	@include font-size($fontSize - 2);
	font-weight: normal;
	@include min-height(14px);
	@include line-height($fontHeight - 2);
	margin: 0;
	padding: 9px 0 9px 50px;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	z-index: 1;

	.icon {
		background: $colorBase;
		content: "";
		display: block;
		height: 38px;
		left: 0;
		margin: -19px 0 0 0;
		padding: 0;
		position: absolute;
		top: 50%;
		width: 40px;
		z-index: 1;

		&:before {
			@include bg('/images/camc/common/sprite.png', no-repeat, -176px -95px);
			content: "";
			display: block;
			height: 24px;
			left: 50%;
			margin: -12px 0 0 -12px;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 24px;
			z-index: 1;
		}
	}

	&:hover {
		color: $colorPrimary;
		text-decoration: none;

		.icon {
			background: $colorPrimary;
		}
	}
}
