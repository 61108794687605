@import "../config";

$bpSliderLimit: $bpLarge + 60;

.bx {
	&-prev, &-next {
		bottom: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		@include text-hidden();
		top: 0;
		width: 48px;
		z-index: 100;

		&:before {
			@include bg('/images/camc/common/sprite.png', no-repeat, -236px -202px);
			content: "";
			display: block;
			height: 50px;
			left: 50%;
			margin: -25px 0 0 -9px;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 18px;
			z-index: 1;
		}

		&:hover {
			@include opacity(.5);
		}

		&.disabled {
			display: none;
		}
	}

	&-prev {
		left: 0;

		&:before {
			.bx-white & {
				background-position-x: -292px;
			}
		}

		.bx-offset & {
			@include mq-min($bpSliderLimit) {
				left: -39px;
			}
		}
	}

	&-next {
		right: 0;

		&:before {
			background-position: -264px -202px;

			.bx-white & {
				background-position-x: -320px;
			}
		}

		.bx-offset & {
			@include mq-min($bpSliderLimit) {
				right: -39px;
			}
		}
	}

	&-pager {
		bottom: 0;
		font-size: 0;
		left: 0;
		line-height: 0;
		margin: -8px 0 0 -8px;
		padding: 15px;
		position: absolute;
		right: 0;
		text-align: center;
		z-index: 1000;

		&-item {
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 8px 0 0 8px;

			@include mq-min($bpTabletLarge) {
				padding: 6px 0 0 6px;
			}

			@include mq-min($bpDesktop) {
				padding: 7px 0 0 7px;
			}

			@include mq-min($bpDesktopLarge) {
				padding: 8px 0 0 8px;
			}
		}

		&-link {
			background: $colorGreyMid;
			@include border-radius(100%);
			display: block;
			height: 8px;
			margin: 0;
			padding: 0;
			@include text-hidden();
			width: 8px;

			&:hover, &:focus, &.active {
				background: $colorPrimary;

				.bx-white & {
					background: $colorWhite;
				}
			}
		}
	}
}
