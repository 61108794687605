@import "../config";

.l-page
{
	background: $colorWhite;
	margin: 0;
	padding: 0 10px;
	&__inner
	{
		margin: 0;
		padding: 15px 0 25px 0;
		@include mq-min($bpTabletLarge)
		{
			padding: 20px 0 30px 0;
		}
		@include mq-min($bpDesktop)
		{
			padding: 25px 0 35px 0;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 30px 0 40px 0;
		}
	}
	&__content
	{
		@include box-sizing();
		margin: 0 auto;
		padding: 15px 0 25px 0;
		text-align: left;
		max-width: $maxPageWidth;
	}
}