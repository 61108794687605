@import "../config";

$cutoff: $bpTabletLarge + 50;

.m-product
{
	clear: both;
	margin: 0;
	padding: 0;
	&__inner
	{
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
	}
	&__details
	{
		@include box-sizing();
		margin: 0;
		padding: 0;
		text-align: left;
		@include mq-min($cutoff)
		{
			@include min-height(690px);
			padding-left: 52%;
			padding-left: calc(50% + 15px);
		}
		@include mq-min($bpDesktop)
		{
			padding-left: 513px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-left: 528px;
		}
		@include mq-min($bpLarge)
		{
			padding-left: 543px;
		}
	}
	&__title
	{
		margin: 0;
		padding: 0 0 15px 0;
	}
	&__part
	{
		font-family: $fontBold;
		@include font-size($fontSize + 2);
		margin: 0;
		overflow: hidden;
		padding: 0 0 5px 0;
		&__title
		{
			@include box-sizing();
			float: left;
			font-family: $fontBase;
			@include font-size($fontSize);
			margin: 0;
			padding: 0 5px 0 0;
			width: 50%;
			max-width: 123px;
		}
		@include mq-min($cutoff)
		{
			margin-top: -5px;
		}
	}
	&__rating
	{
		margin: 0;
		padding: 5px 0 0 0;
	}
	&__gallery
	{
		@include box-sizing();
		font-size: 0;
		line-height: 0;
		margin: 0 auto 15px auto;
		padding: 0;
		position: relative;
		max-width: 840px;
		z-index: 1;
		&__inner
		{
			margin: 0;
			padding: 0;
		}
		&__group
		{
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;
			.bx-wrapper
			{
				margin: 0 !important;
			}
			&--gallery
			{
				border: 1px solid $colorDivider;
				margin: 0 0 10px 0;
				padding: 5px;
			}
			&--pager
			{
				display: none;
				margin: 0;
				padding: 0;
				.js &
				{
					display: block;
				}
			}
		}
		&__menu
		{
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 0;
			@include ratio($ratioImageProductWidth, $ratioImageProductHeight);
			position: relative;
			&__item
			{
				margin: 0;
				padding: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
			&__zoom
			{
				bottom: 0;
				@include box-sizing();
				color: $colorPrimary;
				font-family: $fontBold;
				@include font-size($fontSize - 2);
				font-weight: normal;
				left: 0;
				@include line-height($fontHeight - 2);
				margin: 0;
				padding: 0;
				position: absolute;
				right: 0;
				text-decoration: none;
				text-transform: uppercase;
				top: 0;
				z-index: 100;
				&.focus-visible {
					@include focus-style-alt();
				}
				&__inner
				{
					background: rgba($colorWhite, .5);
					bottom: 5px;
					left: 50%;
					margin: 0;
					padding: 10px 20px;
					position: absolute;
					@include transform(translateX(-50%));
					z-index: 1;
					&:after
					{
						content: " +";
					}
				}
				&:hover, &:focus
				{
					color: $colorBase;
					text-decoration: none;
				}
			}
		}
		&__pager
		{
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 0;
			&__item
			{
				margin: 0;
				padding: 0;
				width: 123px; // matches that set in product.js
			}
			&__link
			{
				border: 1px solid $colorDivider;
				display: block;
				margin: 0;
				padding: 5px;
				&:hover, &:focus, &.active
				{
					border-color: $colorGreyMid;
				}
				&.focus-visible {
					@include focus-style-alt();
				}
			}
		}
		@include mq-min($cutoff)
		{
			left: 0;
			margin: 0;
			position: absolute;
			width: 50%;
			top: 0;
		}
		@include mq-min($bpDesktop)
		{
			width: 493px;
		}
	}
	&__prices
	{
		clear: both;
		margin: 0;
		padding: 15px 0;
	}
	&__price
	{
		@include box-sizing();
		color: $colorGreyMid;
		@include font-size($fontSize - 1);
		@include line-height($fontHeight + 1);
		margin: 0;
		overflow: hidden;
		padding: 0 5px 0 0;
		width: 100%;
		&__title
		{
			@include box-sizing();
			float: left;
			font-family: $fontBase;
			margin: 0;
			padding: 0 5px 0 0;
			width: 123px;

			&--small {
				width: 100px;
			}
		}
		&__value
		{
			margin: 0;
			overflow: hidden;
			padding: 0;
		}
		&__aside
		{
			padding-left: 5px;
			&--rrp
			{
				color: $colorGreyMid;
				del
				{
					font-family: $fontBold;
				}
			}
			&--small {
				font-size: 12px;
			}
		}
		&__help
		{
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 5px 0 0 123px;
			&--small {
				font-size: 12px;
				padding-left: 100px;
			}
			&__link
			{
				color: $colorGreyMid;
				font-family: $fontBase;
				text-decoration: underline;
				&:hover, &:focus
				{
					color: $colorGreyMid;
					text-decoration: none;
				}
			}
		}
		&--large
		{
			color: $colorSecondary;
			.m-product__price__title
			{
				font-family: $fontBold;
				padding-top: 2px;
			}
			.m-product__price__value
			{
				font-family: $fontHeavy;
				@include font-size($fontSize + 6);
			}
		}
		&--alt
		{
			color: $colorPrimary;
		}
	}
	&__stock
	{
		color: $colorGreyMid;
		margin: 0;
		padding: 0 0 15px 0;
		&__title
		{
			color: $colorPrimary;
			font-weight: 600;
			@include font-size($fontSize + 3);
			@include line-height($fontHeight + 3);
			margin: 0 0 10px 0;
			padding: 0;
		}
		&__status
		{
			margin: 0;
			padding: 0;
		}
	}
	&__quantity
	{
		margin: 0;
		padding: 0 0 20px 0;
		label
		{
			color: $colorGrey;
			display: block;
			margin: 0;
			padding: 0 0 5px 0;
		}
		input
		{
			width: 100%;
			max-width: 100px;
		}
	}
	&__variants
	{
		margin: 0;
		padding: 0;
		&__item
		{
			max-width: 400px;
		}
	}
	&__add
	{
		margin: 0;
		padding: 0 0 35px 0;
	}
	&__section
	{
		margin: 0;
		padding: 0;
		&__title
		{
			color: $colorGreyLight;
			font-family: $fontBold;
			@include font-size($fontSize + 3);
			@include line-height($fontHeight + 3);
			margin: 0;
			padding: 0 0 10px 0;
		}
	}
	&__share
	{
		margin: 0;
		padding: 20px 0 0 0;
		@include mq-min($cutoff)
		{
			bottom: 0;
			left: 0;
			padding: 0;
			position: absolute;
			z-index: 1;
		}
	}
	&__spec
	{
		//background: $colorOffWhite;
		margin: 0;
		padding: 0;
		&__table
		{
			margin: 0;
			padding: 0;
			table
			{
				margin: -5px;
				tr
				{
					th, td
					{
						border: 0;
						border-top: 1px solid $colorOffWhite;
						margin: 0;
						padding: 5px;
						@include mq-min($bpTablet)
						{
							padding: 10px;
						}
					}
					th
					{
						color: $colorGreyMid;
						font-weight: normal;
						width: 40%;
						&:after
						{
							content: ":";
						}
						@include mq-min($bpMobileLarge)
						{
							width: 140px;
						}
						@include mq-min($bpTablet)
						{
							width: 160px;
						}
					}
					&:first-child
					{
						th, td
						{
							border-top: 0;
						}
					}
				}
			}
		}
		&__actions
		{
			margin: 0;
			padding: 15px 0 0 0;
			@include mq-min($bpTabletLarge)
			{
				padding-top: 20px;
			}
			@include mq-min($bpDesktop)
			{
				padding-top: 25px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding-top: 30px;
			}
		}
	}
}
