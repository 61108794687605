﻿@import "../config";

.m-nav-section
{
	margin: 0;
	padding: 0;
	&__title
	{
		@include font-size($fontSize + 2);
		@include line-height($fontHeight + 2);
		margin: 0;
		padding: 15px 0 0 0;
		&__link
		{
			color: $colorBase;
			display: block;
			margin: 0;
			padding: 8px 0;
			text-decoration: none;
			&:hover
			{
				color: $colorPrimary;
				text-decoration: none;
			}
		}
		&:first-child
		{
			padding-top: 0;
		}
	}
	&__menu
	{
		border-top: 1px solid $colorDivider;
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 10px 0 0 0;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize);
			@include line-height($fontHeight);
			margin: 0;
			padding: 5px 5px 0 0;
			width: 50%;
			@include mq-min($bpTablet)
			{
				width: 100%;
			}
			&--2
			{
				@include mq-min($bpDesktop)
				{
					width: 50%;
				}
			}
		}
		&__link
		{
			color: $colorGreyMid;
			display: block;
			font-family: $fontBase;
			margin: 0;
			padding: 8px 0;
			text-decoration: none;
			&:hover
			{
				color: $colorPrimary;
				text-decoration: none;
			}
		}
	}
}