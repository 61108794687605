@import "../config";

.l-footer
{
	background: $colorOffWhite;
	margin: 0 -10px;
	padding: 20px 15px 30px 15px;
	&__inner
	{
		@include box-sizing();
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0 auto;
		padding: 0;
		position: relative;
		text-align: left;
		max-width: $maxPageWidth;
		z-index: 1;
	}
	&__copyright
	{
		color: $colorGreyMid;
		@include font-size($fontSize - 2);
		@include line-height($fontHeight - 2);
		margin: 0;
		padding: 0;
		@include mq-min($bpTablet)
		{
			float: left;
			padding-right: 20px;
		}
		@include mq-min($bpDesktop)
		{
			padding-right: 30px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-right: 40px;
		}
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 5px 0 0 0;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize - 2);
			@include line-height($fontHeight - 2);
			margin: 0 10px 0 0;
			padding: 0 10px 0 0;
			position: relative;
			vertical-align: top;
			z-index: 1;
			&:before
			{
				border-left: 1px solid $colorGreyLight;
				bottom: 3px;
				content: "";
				display: block;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 0;
				top: 3px;
				width: 1px;
				z-index: 1;
			}
			@include mq-min($bpDesktop)
			{
				margin-right: 10px;
				padding-right: 10px;
			}
			&:last-child
			{
				margin-right: 0;
				padding-right: 0;
				&:before
				{
					display: none;
				}
			}
		}
		&__link
		{
			color: $colorGreyMid;
			display: block;
			font-family: $fontBase;
			margin: 0;
			padding: 0;
			text-decoration: none;
			&:hover, &:focus
			{
				color: $colorPrimary;
				text-decoration: none;
			}
		}
		@include mq-min($bpTablet)
		{
			float: left;
			margin-right: 140px;
			padding: 0;
		}
	}
	&__info
	{
		font-family: $fontBold;
		margin: 0 auto;
		padding: 0 0 5px 12px;
		position: relative;
		max-width: $maxContentWidth;
		z-index: 1;
		&__marker
		{
			color: $colorPrimary;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 2px;
			z-index: 1;
		}
		@include mq-min($bpTabletLarge)
		{
			padding-bottom: 10px;
		}
		@include mq-min($bpDesktop)
		{
			padding-bottom: 15px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-bottom: 20px;
		}
	}
	&__created
	{
		clear: both;
		@include font-size($fontSize - 2);
		max-height: 20px;
		@include line-height($fontHeight - 2);
		margin: 0;
		padding: 15px 0 0 0;
		max-width: 140px;
		&__link
		{
			color: $colorGreyMid;
			font-family: $fontBase;
			text-decoration: none;
			&:hover, &:focus
			{
				color: $colorPrimary;
				text-decoration: none;
			}
		}
		@include mq-min($bpDesktop)
		{
			bottom: 0;
			padding: 0;
			position: absolute;
			right: 0;
			z-index: 1;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 25px 15px 30px 15px;
	}
	@include mq-min($bpDesktop)
	{
		padding: 30px 15px 35px 15px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 35px 15px 40px 15px;
	}
}