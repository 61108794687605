﻿@import "../config";

.m-usp
{
	background: $colorOffWhite;
	margin: 1px -10px 0 -10px;
	padding: 0;
	text-align: center;
	&__inner
	{
		@include box-sizing();
		margin: 0 auto;
		padding: 0;
		text-align: left;
		max-width: 1100px;
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		&__item
		{ 
			background: $colorWhite;
			@include box-sizing();
			color: $colorSecondary;
			display: inline-block;
			font-family: $fontBold;
			@include font-size($fontSize - 2);
			font-weight: normal;
			@include line-height($fontHeight - 2);
			margin: 2px 0 0 0;
			padding: 10px;
			position: relative;
			text-align: left;
			vertical-align: top;
			width: 100%;
			z-index: 1;
			@include mq-min($bpTablet)
			{
				@include font-size($fontSize);
			}
			@include mq-min($bpTabletLarge)
			{
				margin: 0 auto;
				padding: 0;
				width: 33.3333%;
				max-width: 220px;
			}
			&:first-child
			{
				margin-top: 0;
			}
		}
		&__icon
		{
			float: left;
			margin: 1px 10px 0 0;
			padding: 0;
			max-width: 25px;
			@include mq-min($bpTabletLarge)
			{
				max-width: 45px;
			}
		}
		&__content
		{
			margin: 0;
			overflow: hidden;
			padding: 0;
			@include mq-min($bpTabletLarge)
			{
				text-align: center;
			}
		}
		&__line-1
		{
			font-family: $fontBold;
			font-weight: normal;
			@include mq-min($bpTabletLarge)
			{
				display: block;
			}
			@include mq-min($bpDesktop)
			{
				@include font-size($fontSize + 2);
			}
		}
		&__link
		{
			bottom: 0;
			display: block;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			@include text-hidden();
			top: 0;
			z-index: 2;
		}
		@include mq-min($bpTabletLarge)
		{
			display: flex;
			justify-content: space-between;
			margin: 0;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		background: $colorWhite;
		padding: 15px;
	}
	@include mq-min($bpDesktop)
	{
		padding: 20px 15px;
	}
}