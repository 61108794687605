﻿@import "../config";

.l-content
{
	margin: 0;
	padding: 0;
	&__title
	{
		font-size: 0;
		line-height: 0;
		margin: -10px 0 0 -10px;
		padding: 0 0 15px 0;
		&__mega
		{
			display: inline-block;
			@include font-size($fontSize + 18);
			@include line-height($fontHeight + 18);
			margin: 0;
			padding: 10px 0 0 10px;
		}
		&__primary
		{
			display: inline-block;
			@include font-size($fontSize + 14);
			@include line-height($fontHeight + 14);
			margin: 0;
			padding: 10px 0 0 10px;
		}		
		&__secondary
		{
			color: $colorGreyMid;
			display: inline-block;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 10px 0 0 10px;
			@include mq-min($bpTabletLarge)
			{
				&--results {
					padding-top: 18px;
				}
			}
		}
		&__action
		{
			display: inline-block;
			font-family: $fontBold;
			@include font-size($fontSize - 1);
			font-weight: normal;
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 10px 0 0 10px;
		}
		&--spaced
		{
			@include mq-min($bpTabletLarge)
			{
				padding-bottom: 20px;
			}
			@include mq-min($bpDesktop)
			{
				padding-bottom: 25px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding-bottom: 30px;
			}
		}
		&--tight
		{
			padding-bottom: 0;
		}
	}
	&__sub-title
	{
		font-size: 0;
		font-weight: normal;
		line-height: 0;
		margin: -10px 0 0 -10px;
		padding: 0 0 15px 0;
		&__primary
		{
			display: inline-block;
			@include font-size($fontSize + 5);
			@include line-height($fontHeight + 5);
			margin: 0;
			padding: 10px 0 0 10px;
		}		
		&__secondary
		{
			display: inline-block;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 10px 0 0 10px;
		}
		&--spaced
		{
			@include mq-min($bpTabletLarge)
			{
				padding-bottom: 20px;
			}
			@include mq-min($bpDesktop)
			{
				padding-bottom: 25px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding-bottom: 30px;
			}
		}
		&--tight
		{
			padding-bottom: 0;
		}
	}
	&__summary
	{
		@include font-size($fontSize + 4);
		@include line-height($fontHeight + 4);
		margin: 0;
		padding: 0 0 5px 0;
	}
}