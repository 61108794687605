﻿@import "../config";

.l-search
{
	background: $colorSecondary;
	border-bottom: 2px solid $colorWhite;
	color: $colorWhite;
	font-size: 0;
	line-height: 0;
	margin: 0 -10px;
	padding: 10px;
	text-align: center;
	&__inner
	{
		margin: 0 auto;
		padding: 0;
		.form-row
		{
			display: inline-block;
			margin: 0;
			padding: 0;
			text-align: center;
			vertical-align: middle;
			width: 100%;
			.form-text
			{
				height: 45px;
				width: 70%;
				width: calc(100% - 45px);	
				max-width: none;			
				@include mq-min($bpTablet)
				{
					max-width: 395px;
				}
			}
			.form-button
			{
				height: 45px;
				width: 45px;
			}
			@include mq-min($bpTablet)
			{
				max-width: 400px;
			}
			@include mq-min($bpTabletLarge)
			{
				max-width: 440px;
			}
		}
	}
	&__title
	{
		display: none;
		@include font-size($fontSize + 3);
		@include line-height($fontHeight + 2);
		margin: 0;
		padding: 0 15px 0 0;
		vertical-align: middle;
		@include mq-min($bpTablet)
		{
			display: inline-block;
		}
		@include mq-min($bpTabletLarge)
		{
			@include font-size($fontSize + 5);
			@include line-height($fontHeight + 4);
			padding-right: 20px;
		}
	}
	@include mq-min($bpDesktop)
	{
		padding: 18px;
	}
}