﻿@import "../config";

.m-notice
{
	background: $colorPrimary;
	color: $colorWhite;
	@include box-sizing();
	margin: 0 auto 20px auto;
	padding: 15px 15px 0 15px;
	&__inner
	{
		margin: 0;
		padding: 0;
		a.focus-visible {
			@include focus-style-alt($colorBase);
		}
	}
	&__title
	{
		@include font-size($fontSize + 10);
		@include line-height($fontHeight + 10);
		margin: 0;
		padding: 0 0 15px 0;
	}
	&__content
	{
		margin: 0;
		padding: 0;
		a
		{
			color: $colorBase;
			font-weight: normal;
			text-decoration: underline;
			&:hover
			{
				color: $colorBase;
				text-decoration: none;
			}
			&.focus-visible {
				@include focus-style-alt($colorBase);
			}
		}
	}
	@include mq-min($bpDesktop)
	{
		padding: 20px 20px 5px 20px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 25px 25px 10px 25px;
	}
	&--slim
	{
		padding: 15px 15px 0 15px;
	}
	&--tight
	{
		margin-bottom: 0;
	}
}