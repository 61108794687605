﻿@mixin wide-extras-layout {
	@media screen and (min-width: 720px) and (max-width: 838px), screen and (min-width: $bpLarge) {
		@content;
	}

	@media screen and (min-width: 720px) {
		@at-root .atb #{&} {
			@content;
		}
	}
}

@mixin atb-inbetween-layout {
	@media screen and (min-width: 989px) and (max-width: 1120px), 
		screen and (min-width: 720px) and (max-width: 800px) {
		@at-root .atb #{&} {
			@content;
		}
	}
}

.c-recommended-extras {
	overflow: auto;

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background: $cRecommendExtrasScrollbarTrackColor;
	}

	&::-webkit-scrollbar-thumb {
		background: $cRecommendExtrasScrollbarColor;
	}

	::-webkit-scrollbar-thumb:hover {
		background: lighten($cRecommendExtrasScrollbarColor, 25%);
	}

	@include wide-extras-layout {
		height: auto !important; // Legitimate use of important? I reckon!  Overriding inline styles added to make the container scroll on mobile / tablet
	}
}

.c-recommended-extras__list {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: left;
}

.c-recommended-extras__list--in-page {
	margin: 0 0 20px 0;
}

.c-recommended-extras__item {
	margin: 0;
	padding: 0 0 0 135px;
	position: relative;
	min-height: 89px;

	@include wide-extras-layout {
		padding-right: 42%;
		padding-top: 10px;
		min-height: 79px;
	}

	@include atb-inbetween-layout {
		padding-right: 47%;
	}

	&:not(:first-child) {
		margin-top: 28px;
	}
}

.m-recommended-extras__item__image {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 115px;
	border: 1px solid $cRecommendedExtrasImageBorderColor;

	&:hover,
	&:focus {
		border-color: $cRecommendedExtrasImageBorderHoverColor;
	}

	&.focus-visible {
		@include focus-style-alt();
	}
}

.m-recommended-extras__item__image__element {
	display: block;
	width: 115px;
	height: 87px;
}

.c-recommended-extras__item__title {
	position: relative;
	top: -4px;
	padding: 0;
	font-size: 14px;
	line-height: 20px;

	@include wide-extras-layout {
		position: static;
	}
}

.c-recommended-extras__item__title__link {
	color: $cRecommendExtrasTitleColor;
	font-family: $fontBase;

	&:hover,
	&:focus {
		color: $cRecommendExtrasTitleHoverColor;
	}
}

.c-recommended-extras__item__price__group {
	display: flex;
	margin: 0 0 10px 0;

	@include wide-extras-layout {
		margin: 0;
		position: absolute;
		top: 10px;
		left: 59%;
		max-width: 175px;
		overflow: hidden;
	}

	@include atb-inbetween-layout {
		left: 54%;
	}
}

.c-recommended-extras__item__price {
	font-size: 17px;
	line-height: 17px;
	font-weight: 700;
	color: $cRecommendExtrasPriceColor;
	padding: 0;
	font-family: $fontHeavy;
	font-weight: 400;

	@include wide-extras-layout {
		font-size: 19px;
		line-height: 19px;
	}
}

.c-recommended-extras__item__price--members {
	color: $cRecommendExtrasMembersPriceColor;
	padding: 0 10px;
}

.c-recommended-extras__item__price__title {
	display: block;
	font-size: 12px;
	font-family: $fontBold;
}

.c-recommended-extras__item__price--sale {
	color: $cRecommendExtrasPriceSaleColor;
}

.c-recommended-extras__item__price__vat {
	margin-top: 5px;
	display: block;
	font-size: 11px;
	font-weight: 400;
	color: #9d9d9d;
}

.c-recommended-extras__item__price__was {
	font-size: 13px;
	color: $cRecommendExtrasPriceWasColor;

	@include wide-extras-layout {
		display: block;
		font-size: 14px;
		padding: 0 0 4px 0;
		margin: -3px 0 0 0;
	}
}

.c-recommended-extras__item__form {

	@include wide-extras-layout {
		position: absolute;
		top: 10px;
		right: 0;
	}

	.form-button {
		margin: 0;
		min-width: 120px;
		padding: 10.5px 15px;
	}
}

.c-recommended-extras__item__form.c-recommended-extras__item__form--inline {
	position: relative;
	top: inherit;
	right: inherit;
	margin: 10px 10px 0 0;
}

.c-recommended-extras__item__status,
.c-recommended-extras__item__message {
	padding: 0;
	margin: 5px 0 0 0;
	font-size: 13px;
	line-height: 19px;
	color: $cRecommendExtrasStatusMessageColor;

	@include wide-extras-layout {
		font-size: 12px;
		line-height: 18px;
		font-weight: 400;
		font-family: $fontBold;
	}
}

.c-recommended-extras__item__status {

	@include wide-extras-layout {
		position: absolute;
		top: 48px;
		right: 0;
		width: 120px;
		text-align: center;

		&.has-added {
			width: 138px;
			text-align: right;
		}
	}

	&.c-recommended-extras__item__status--inline {
		position: static;
		top: inherit;
		right: inherit;

		&.has-added {
			text-align: left;
		}
	}
}

.c-recommended-extras__item__status-icon {
	display: none;
	vertical-align: middle;
	width: 14px;
	height: 14px;
}

.c-recommended-extras__item__status.has-added .c-recommended-extras__item__status-icon {
	display: inline-block;
}

.c-recommended-extras__item__dispatch {
	margin: 5px 0 0 0;
}

.c-recommended-extras__item__message {
	margin: 0;
	position: relative;
	top: -2px;
	padding: 0 0 4px 0;

	@include wide-extras-layout {
		position: static;
		padding: 0;
	}
}
