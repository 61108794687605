@import "../config";

html.html--atb-lockout-fixed-heights {
	overflow: hidden;

	body {
		overflow: hidden;
	}
}

html.html--atb-lockout {
	height: 100%;
	position: fixed;
	width: 100%;

	body {
		position: static;
		width: auto;
	}
}

.atb {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999999999; // lift atb above the crazy chat bubble
	display: none;
	@include opacity(0);
	@include transition(opacity $mAtbAnimationTime $mAtbAnimationEasing);
	will-change: opacity;
}

.atb--disallow-animations {
	@include transition(none);
}

.atb--active {
	@include opacity(1);
}

.atb__inner {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.atb__background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $colorBlack;
	@include opacity(0.5);
}

.atb__main {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $colorWhite;
	@include transform(translateX(100%));
	@include transition(transform $mAtbAnimationTime $mAtbAnimationEasing);
	will-change: transform;
	@include box-shadow(0px 0px 2px 2px rgba(0,0,0,0.25));

	.atb--disallow-animations & {
		@include transition(none);
	}

	@include mq-min($mAtbDesktopBp) {
		left: 100%;
		width: calc(100% - #{$mAtbSidePanelWidthTablet});
		@include transform(translateX(0));

		&:after { // in Edge the detail overlay comes in from the right slightly behind the main panel in terms of time creating a gap where you can see the page beneath, this covers it up - sneaky
			content: '';
			height: 100%;
			position: absolute;
			right: -100px;
			width: 100px;
			background: #fff;
			top: 0;
		}
	}

	@include mq-min($mAtbWideBp) {
		width: $mAtbPanelWidth;
	}
}

.atb__main__inner {
	width: 100%;
	height: calc(100% - #{($mAtbFooterHeight + $mAtbHeaderHeight)});
	position: relative;
	-webkit-overflow-scrolling: touch;

	@include mq-max($mAtbDesktopBp - 1) { // must be in a max query
		overflow-x: hidden;
		overflow-y: auto;
		touch-action: pan-y;

		@media screen and (min-height: $mAtbDeepBp) {
			height: calc(100% - #{($mAtbFooterHeight + $mAtbHeaderHeightDeep)});
		}
	}

	@include mq-min($mAtbDesktopBp) {
		overflow-y: scroll;
		height: 100%;
		touch-action: pan-y;
	}
}

.atb__main__inner__inner {
	padding: 0 0 20px 0;

	@include mq-min($mAtbDesktopBp) {
		padding: 0 0 $mAtbFooterHeightDesktop 0;
	}
}

.atb__main__header {
	height: $mAtbHeaderHeight;
	position: relative;
	z-index: 1;

	@media screen and (min-height: $mAtbDeepBp) {
		height: $mAtbHeaderHeightDeep;
	}

	@include mq-min($mAtbDesktopBp) {
		display: none;
	}
}

.atb__main__header__inner {
	background: #f5f5f5;
	text-align: center;
}

.atb__main__header__inner {
	padding: 10px;

	@media screen and (min-height: $mAtbDeepBp) {
		padding: 10px 20px;
	}
}

.atb__main__header__inner__inner {

	@media screen and (min-height: $mAtbDeepBp) {
		position: relative;
		padding: 10px 0 0 100px;
		@include min-height(60px);
	}
}

.atb__main__header__inner__inner__inner {
	font-size: 0;
	line-height: 0;
}

.atb__main__header__image {
	display: none;

	@media screen and (min-height: $mAtbDeepBp) {
		width: 85px;
		height: 65px;
		display: block;
		border: 1px solid #c6c7c7;
		position: absolute;
		left: 0;
		top: 0;
	}
}

.atb__main__header__message {
	margin: 0;
	padding: 0;
	font-size: 14px;
	line-height: 20px;
	font-weight: 800;
	position: relative;
	display: inline-block;
	height: 20px;
	overflow: hidden;

	@media screen and (min-height: $mAtbDeepBp) {
		display: block;
		text-align: left;
	}
}

.atb__main__header__title {
	display: none;

	@media screen and (min-height: $mAtbDeepBp) {
		display: block;
		text-align: left;
		margin: 0;
		padding: 8px 0 0 0;
		font-size: 14px;
		line-height: 18px;
		height: 36px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

.atb__main__added {
	display: none;

	@include mq-min($mAtbDesktopBp) {
		background: #f5f5f5;
		text-align: left;
		display: block;
	}
}

.atb__main__added__inner {

	@include mq-min($mAtbDesktopBp) {
		padding: 25px 50px 25px 30px;
	}
}

.atb__main__added__inner__inner {

	@include mq-min($mAtbDesktopBp) {
		position: relative;
		padding: 10px 0 0 110px;
		@include min-height(60px);
	}
}

.atb__main__added__image {

	@include mq-min($mAtbDesktopBp) {
		width: 85px;
		height: 65px;
		display: block;
		border: 1px solid #c6c7c7;
		position: absolute;
		left: 0;
		top: 0;
	}
}

.atb__main__added__message {
	font-size: 14px;
	line-height: 20px;
	font-weight: 800;

	@include mq-min($mAtbDesktopBp) {
		margin: 0;
		padding: 0 0 8px 0;
		position: relative;
		display: block;
	}
}

.atb__main__added__title {
	font-size: 14px;
	line-height: 22px;
	font-weight: 600;

	@include mq-min($mAtbDesktopBp) {
		margin: 0;
		padding: 0 100px 0 0;
		position: relative;
	}
}

.atb__main__added__title__inner {

	@include mq-min($mAtbDesktopBp) {
		max-width: 400px;
		display: inline-block;
	}
}

.atb__main__added__title__price {
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;

	@include mq-min($mAtbDesktopBp) {
		position: absolute;
		right: 0;
		bottom: 2px;
		margin: 0;
		padding: 0;
	}
}

.atb__main__title {
	padding: 0;
	margin: 0;
	background: #fff;
	font-size: 15px;
	line-height: 1.2;
	overflow: hidden;
	font-weight: 600;
	display: block;
	border-bottom: 1px solid #eee;
	padding-bottom: 15px;
	text-align: left;
	font-weight: 600;
	color: $mAtbColorTitle;
	margin: 25px 20px 25px 20px;

	@include mq-min($mAtbDesktopBp) {
		font-size: 16px;
		margin: 35px 30px 25px 30px;
	}
}

.atb__main__list-wrapper {
	padding: 0 20px;

	@include mq-min($mAtbDesktopBp) {
		padding: 0 30px;
	}
}

.atb__main__list-wrapper + .atb__alt__container .atb__main__title--customers {
	@include mq-min($mAtbDesktopBp) {
		margin-top: 0;
	}
}

@include mq-max(990px) {
	.atb__main__list-wrapper + .atb__alt__container {
		background-color: #f0f0f0;
		margin-top: 20px;
		margin-bottom: -20px;
		padding-bottom: 20px;
		padding-top: 20px;
	}

	.atb__main__list-wrapper + .atb__alt__container .atb__main__title {
		background-color: #f0f0f0;
		margin-bottom: 0;
		margin-top: 0;
	}
}

.atb__main__list {
	font-size: 0;
	line-height: 0;
	list-style: none;
	margin: 0 0 0 -20px;
	padding: 0 10px;
	text-align: left;

	@supports (display: flex) {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	@include mq-min($mAtbDesktopBp) {
		padding: 0 20px 20px 20px;
		margin: 0;
	}

	@include mq-min(1150px) {
		width: auto !important;
	}
}

.atb__main__list-wrapper--customers {
	@include mq-max(1150px - 1) {
		overflow-x: scroll;
		overflow-y: hidden;
	}
}

.atb__main__list--customers {
	@include mq-min($mAtbDesktopBp) {
		padding: 0;
		margin: 0 -10px;
	}
}

.atb__main__list__item {
	@include box-sizing();
	display: inline-block;
	font-size: $fontSize;
	line-height: $fontHeight;
	list-style: none;
	margin: 0 0 10px 0;
	overflow: visible;
	padding: 0 10px;
	vertical-align: top;
	width: 200px;

	@include mq-min($mAtbDesktopBp) {
		width: 25%;
	}
}

.atb__main__product {

	@include mq-min($mAtbDesktopBp) {
		border: 1px solid #fff;
		box-shadow: none;
		@include transition(#{border 0.25s ease, box-shadow 0.25s ease});
	}

	.no-touchevents &:hover {

		@include mq-min($mAtbDesktopBp) {
			border-color: $colorDivider;
			@include box-shadow(0 0 10px 0 rgba($colorDivider, .75));
		}
	}
}

.atb__main__product__inner {

	@include mq-min($mAtbDesktopBp) {
		padding: 20px;
	}
}

.atb__main__product__image__link {
	display: block;
	padding-top: 100%;
	position: relative;

	img {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	&:focus {
		outline: none;
	}

	.no-touchevents &:hover {

		img {
			@include opacity(1);
		}
	}
}

.atb__main__product__title {
	font-size: 14px;
	line-height: 18px;
	margin: 15px 0 0 0;
	padding: 12px 0 0 0;
	border-top: 1px solid $colorDivider;
	font-weight: 600;
}

.atb__main__product__title__link {
	color: $mAtbColorTitle;

	&:focus {
		outline: none;
	}

	.no-touchevents &:hover {
		text-decoration: underline;
	}
}

.atb__main__product__review {
	padding: 12px 0 0 0;

	.mStars > .stars.small {
		padding-right: 65px;

		.total {
			width: 60px;
		}
	}
}

.atb__main__product__price {
	font-size: 0;
	line-height: 0;
	margin: 0;
	padding: 12px 0 0 0;
}

.atb__main__product__quantity {
	margin: 0;
	padding: 10px 0 0 0;
}

.atb__main__product__price__amount {
	font-size: 26px;
	line-height: 26px;
	text-transform: uppercase;
	display: inline-block;
	margin: 0;
	padding: 0;
	font-weight: 600;
	color: $mAtbColorTitle;

	.pence {
		font-size: 14px;
		line-height: 14px;
	}
}

.atb__main__product__price__rrp {
	display: inline-block;
	margin: 0;
	padding: 0 0 0 5px;
	font-size: 13px;
	line-height: 13px;
	color: #999;
	text-decoration: line-through;
}

.atb__main__product__action,
.atb__detail__item__action {
	margin: 0 0 30px 0;

	a {
		position: relative;
		display: inline-block;
		border-bottom: 1px solid $colorLink;
		padding: 0 0 5px 0;

		&:hover,
		&:focus {
			color: $colorBase;
			border-bottom-color: $colorBase;
		}
	}
}

.atb__detail__item__action__icon {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
}

.atb__main__product__action__view {
	text-decoration: none;
	color: $colorLink;

	&:focus {
		outline: none;
	}

	.no-touchevents &:hover {
		color: $colorLink;
		text-decoration: underline;
	}
}

.atb__main__product__stock,
.atb__detail__item__stock {
	margin: 0;
	padding: 12px 0 0 0;

	.stock {
		color: $colorGrey;
		@include min-height(17px);
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;

		&:before {
			background: url(/images/misc/stock-crosses.png) no-repeat 0 0;
			content: "";
			display: none;
			height: 15px;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 2px;
			width: 17px;
			z-index: 1;

			@include mq-min($bpDesktop) {
				display: block;
			}
		}

		@include mq-min($bpDesktop) {
			padding: 0 0 0 21px;
		}

		&.in {
			color: $mAtbColorSuccess;

			&:before {
				background: url(/images/misc/stock.png) no-repeat 0 100%;
				top: 0;
			}
		}

		&.delivery {
			color: $mAtbColorDelivery;
			font-size: $fontSize - 1;
			padding-left: 0;

			@include mq-min($bpDesktop) {
				padding-left: 24px;
			}

			&:before {
				background: url(/images/misc/stock-delivery.png) no-repeat 0 0;
				top: 1px;
				width: 20px;
			}

			&.in {
				&:before {
					background-position: 0 100%;
				}
			}
		}
	}
}

.atb__main__product__added,
.atb__detail__item__added {
	margin: 0;
	padding: 12px 0 0 0;
	display: none;
}

.atb__main__product__added__message,
.atb__detail__item__added__message {
	margin: 0;
	padding: 0 0 0 21px;
	position: relative;
	color: #1ba31a;
	@include min-height(17px);

	&:before {
		content: '';
		display: block;
		width: 17px;
		height: 13px;
		background: url(/images/camc/atb/sprite.png) no-repeat 0 0;
		position: absolute;
		top: 2px;
		left: 0;
	}
}

.atb__main__footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: #f5f5f5;
	height: ($mAtbFooterHeight - 1);
	z-index: 10;
	overflow: hidden;

	@include mq-max($mAtbDesktopBp - 1) {
		right: 0 !important;
	}

	@include mq-min($mAtbDesktopBp) {
		height: ($mAtbFooterHeightDesktop - 1);
	}
}

.atb__main__footer__inner {
	height: ($mAtbFooterHeight - 1);
	position: relative;

	@include mq-min($mAtbDesktopBp) {
		height: ($mAtbFooterHeightDesktop - 1);
	}
}

.atb__main__footer__basket {
	position: absolute;
	top: 50%;
	right: 10px;
	@include transform(translateY(-50%));
	text-align: right;
	font-size: 0;
	line-height: 0;

	@include mq-max($mAtbDesktopBp - 1) {

		@media screen and (min-height: $mAtbDeepBp) {
			right: 20px;
		}
	}

	@include mq-min($mAtbDesktopBp) {
		right: 30px;
	}
}

.atb__main__footer__basket__message {
	display: none;

	@include mq-min(625px) {
		display: inline-block;
		margin: 0;
		padding: 0;
		font-size: 16px;
		line-height: 16px;
		position: relative;
		top: 6px;
		right: 20px;
		position: relative;
	}
}

.atb__main__footer__basket__message__detail {
	display: none;

	@include mq-min(800px) {
		display: inline;
	}

	@include mq-min($mAtbDesktopBp) {
		display: none;
	}

	@include mq-min($mAtbWideBp) {
		display: inline;
	}
}

.atb__main__footer__basket__button {
	margin: 0;
	position: relative;
	padding: 11px 39px 11px 20px;

	&:focus {
		outline: none;
	}

	&:before {
		content: '';
		display: block;
		background: url(/images/camc/atb/sprite.png) no-repeat -105px 0;
		width: 11px;
		height: 20px;
		position: absolute;
		top: 50%;
		right: 15px;
		@include transform(translateY(-50%));
	}
}

.atb__main__footer__close {
	position: absolute;
	top: 50%;
	left: 10px;
	margin-top: -19px;
	padding: 11px 20px;

	@include mq-max($mAtbDesktopBp - 1) {

		@media screen and (min-height: $mAtbDeepBp) {
			left: 20px;
		}
	}

	@include mq-min($mAtbDesktopBp) {
		left: 30px;
		margin-top: -22px;
	}

	&:focus {
		outline: none;
	}

	.no-touchevents &:not(.form-button--line):hover {
		color: $colorLinkHover;

		&:before {
			background-position: -72px 0;
		}
	}
}

.atb__main--active {
	@include transform(translateY(0));

	@include mq-min($mAtbDesktopBp) {
		@include transform(translateX(-100%));
	}
}

.atb__main--detail-active {

	@include mq-min($mAtbDesktopBp) {
		@include transform(translateX(-100%) translateX(-#{$mAtbSidePanelWidthTablet}));
	}

	@include mq-min($mAtbWideBp) {
		@include transform(translateX(-100%) translateX(-#{$mAtbSidePanelWidth}));
	}
}

.atb__detail {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $colorWhite;
	@include transform(translateX(100%));
	@include transition(transform $mAtbAnimationTime $mAtbAnimationEasing);
	will-change: transform;
	text-align: left;

	.atb--disallow-animations & {
		@include transition(none);
	}

	@include mq-min($mAtbDesktopBp) {
		left: 100%;
		width: $mAtbSidePanelWidthTablet;
		@include transform(translateX(0));
	}

	@include mq-min($mAtbWideBp) {
		width: $mAtbSidePanelWidth;
	}
}

.atb__detail__fade {
	height: 61px;
	position: absolute;
	left: 10px;
	right: 0;
	z-index: 1;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+50,0+100 */
	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
	@include mq-min(800px) {

		@include mq-max($mAtbDesktopBp - 1) {
			display: none;
		}
	}
}

.atb__detail__inner {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	touch-action: pan-y;
	box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.4);
}

.atb__detail__inner__inner {
	padding: 63px 0 30px 0;
}

.atb__detail__item {
	padding: 0 30px;

	@include mq-max($mAtbDesktopBp - 1) {
		max-width: 545px;
		margin: 0 auto;
	}

	.bx-wrapper {
		position: relative;
		padding: 0 10px;

		.bx-pager {
			font-size: 0;
			line-height: 0;
			text-align: center;
			margin: 10px 0 0 0;
			position: relative;
			bottom: inherit;
			left: inherit;
		}

		.bx-prev,
		.bx-next {
			margin-top: -20.5px;

			&:focus {
				outline: none;
			}
		}

		.bx-prev {
			left: -30px;
		}

		.bx-next {
			right: -30px;
		}

		.bx-pager-item {
			width: 6px;
			height: 6px;
			display: inline-block;

			&:not(:first-child) {
				margin-left: 10px;
			}

			.bx-pager-link {
				display: block;
				width: 6px;
				height: 6px;
				background: #FFF;
				border: 1px solid #b7b7b7;
				border-radius: 100%;
				text-align: left;
				text-indent: -9000px;

				&:focus {
					outline: none;
				}

				&.active {
					background: #b7b7b7;
				}
			}
		}
	}
}

.atb__detail__item__slideshow {
	margin: 0;
	padding: 0;
	list-style: none;
}

.atb__detail__item__slideshow__item {
	margin: 0;
	padding: 0;
	border: 1px solid #eaeaea;
}

.atb__detail__item__slideshow__item__inner {
	padding-top: 80%;
	position: relative;
}

.atb__detail__item__slideshow__image {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.atb__detail__item__title {
	padding: 20px 0 10px 0;
	margin: 0;
	text-align: left;
	font-size: 16px;
	line-height: 1.2;
	font-weight: 600;
	color: $mAtbColorTitle;
}

.atb__detail__item__logo {
	float: right;
	max-width: 115px;
	margin: 2px 0 0 0;
}

.atb__detail__item__review {

	.atb__detail__item__logo-container & {
		float: left;
		clear: left;
		width: calc(100% - 135px);
	}

	.mStars > .stars.small {
		padding-right: 65px;

		.total {
			width: 60px;
		}
	}
}

atb__detail__item__price {
	font-size: 19px;
	line-height: 19px;
	font-weight: 700;
	color: $cRecommendExtrasPriceColor;
	padding: 0;
	margin: 5px 0 30px 0;
	margin: 5px 0 15px 0;
}

.atb__detail__item__price__was {
	display: block;
	font-size: 13px;
	color: $cRecommendExtrasPriceWasColor;

	@include mq-min($mAtbDesktopBp) {
		font-size: 14px;
		padding: 0 0 4px 0;
		margin: -3px 0 0 0;
	}
}

.atb__detail__item__price--sale {
	color: $mAtbSaleColor;
}

.atb__detail__item__dispatch {
	margin: 0 0 30px 0;
}

.atb__detail__item__stock {
	padding: 12px 0 0 0;
	margin: 12px 0 0 0;
	border-top: 1px solid $colorDivider;
}

.atb__detail__item__added {
	padding: 17px 0 0 0;
}

.atb__detail__item__features {
	color: $mAtbColorTitle;
}

.atb__detail__item__features__title {
	padding: 0 0 15px 0;
	margin: 0 0 20px 0;
	background: #fff;
	font-size: 15px;
	line-height: 1.2;
	overflow: hidden;
	font-weight: 600;
	display: block;
	border-bottom: 1px solid #eee;
	text-align: left;
	font-weight: 600;
	color: $mAtbColorTitle;
}

.atb__detail__item__features ul {
	margin: 0 0 20px 10px;
	padding: 0;
	list-style: none;
}

.atb__detail__item__features li {
	position: relative;
	padding: 0 0 0 10px;
	margin: 0;
	font-size: 13px;

	&:not(:first-child) {
		margin: 5px 0 0 0;
	}

	&:before {
		content: '';
		display: block;
		width: 3px;
		height: 4px;
		background: $colorBase;
		border-radius: 100%;
		position: absolute;
		top: 8px;
		left: 0;
	}
}

.atb__detail__item__specs {
	padding: 15px 0 0 0;
	margin: 12px 0 0 0;
	border-top: 1px solid $colorDivider;
	font-weight: 600;
}

.atb__detail__item__specs__title {
	font-size: 15px;
	line-height: 15px;
	color: $colorLink;
	margin: 0;
	padding: 0 34px 15px 12px;
	position: relative;
	cursor: pointer;

	&:after {
		content: '';
		display: block;
		width: 17px;
		height: 9px;
		background: url(/images/camc/atb/sprite.png) no-repeat -280px 0;
		position: absolute;
		right: 10px;
		top: 4px;
		will-change: transform;
		@include transition(transform 0.25s ease);
	}
}

.atb__detail__item__specs__title--open {

	&:after {
		@include transform(rotate(180deg));
	}
}

.atb__detail__item__specs__content {
	border-bottom: 1px solid $colorDivider;
}

.atb__detail__item__specs__content__inner {

	.atb__detail__item__specs--hide & {
		display: none;
	}
}

.atb__detail__item__specs__table {
	margin: 0;
	padding: 0;
	font-size: 13px;
	line-height: 17px;
	border-collapse: collapse;
	margin: 0;
	width: 100%;

	tbody {
		tr {
			th, td {
				border: 0;
				margin: 0;
				padding: 5px 8px;

				@include mq-min($mAtbDesktopBp) {
					padding: 8px 15px;
				}
			}

			th {
				color: $colorGrey;
				width: 150px;
			}

			&:nth-child(odd) {
				th, td {
					background: #ebebeb;
				}
			}
		}
	}
}

.atb__detail__item__specs__spacer {

	.atb__detail__item__specs--collapsible & {
		height: 12px;

		@include mq-min($mAtbDesktopBp) {
			height: 17px;
		}
	}
}

.atb__detail__close {
	position: absolute;
	top: 20px;
	right: 20px;
	padding: 0 20px 0 0;
	margin: 0;
	font-size: 12px;
	line-height: 1;
	color: $colorBase;
	background-color: transparent;
	border: none;
	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
	z-index: 2;

	&:before,
	&:after {
		content: '';
		display: block;
		width: 12px;
		height: 2px;
		background: $colorBase;
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: -1px;
		@include transform(rotate(45deg));
	}

	&:after {
		@include transform(rotate(-45deg));
	}

	&:focus {
		outline: none;
	}

	&:hover {
		color: $colorBase;
	}

	.no-touchevents &:hover,
	.no-touchevents &:focus {
		color: $colorPrimary;

		&:before,
		&:after {
			background: $colorPrimary;
		}
	}
}

.atb__detail__loader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	z-index: 10;
	display: none;

	@include mq-max($mAtbDesktopBp - 1) {
		display: none !important;
	}
}

.atb__detail__loader__element {
	display: block;
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform(translate(-50%, -50%));
	width: 40px;
	height: 10px;
}

.atb__detail__loader__placeholder {
	width: 50px;
	height: 10px;
	margin: 50px auto;
}

.atb__detail__loader__circle {
	display: inline-block;
	width: 10px;
	height: 10px;
	background: $mAtbLoaderColor;
	border-radius: 100%;
	animation: loader 1.4s infinite ease-in-out both;

	&:not(:first-child) {
		margin-left: 5px;
	}
}

.atb__detail__loader__circle--1 {
	animation-delay: -0.32s;
}

.atb__detail__loader__circle--2 {
	animation-delay: -0.16s;
}

@keyframes loader {
	0%, 80%, 100% {
		@include transform(scale(0));
	}

	40% {
		@include transform(scale(1.0));
	}
}

.atb__detail--active {
	@include transform(translateY(0));

	@include mq-min($mAtbDesktopBp) {
		@include transform(translateX(-100%));
	}
}

.atb__quantity {
}

.atb__quantity__add {
	margin: 0;
	position: relative;
	padding-left: 50px;
	padding-right: 30px;

	.atb__quantity--large & {
		height: 46px;
		line-height: 30px;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 30px;
		width: 12px;
		height: 12px;
		@include transform(translateY(-50%));
		background: url(/images/camc/atb/sprite.png) no-repeat -138px 0;
	}
}

.atb__quantity__widget {
	font-size: 0;
	line-height: 0;
	display: none;

	@include mq-max($mAtbDesktopBp - 1) {
		position: relative;

		.touch &:after {
			content: '';
			display: block;
			height: 36px;
			width: 45px;
			position: absolute;
			top: 0;
			left: 36px;
			background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7) repeat 0 0;
		}

		.touch .atb__quantity--large &:after {
			height: 46px;
			left: 46px;
			width: 85px;
		}
	}
}

.atb__quantity__widget__button {
	display: inline-block;
	width: 36px;
	height: 36px;
	text-align: left;
	text-indent: -9000px;
	background: $colorLink;
	vertical-align: top;
	position: relative;

	.atb__quantity--large & {
		height: 46px;
		width: 46px;
	}

	.no-touchevents &:hover {
		background: darken($mAtbColorBlue, 5%);
	}

	&:focus {
		outline: none;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%, -50%));
		background: url(/images/camc/atb/sprite.png) no-repeat -9999px -9999px;
	}
}

.atb__quantity__widget__button--less {

	&:after {
		background-position: -172px 0;
		width: 10px;
		height: 3px;
	}
}

.atb__quantity__widget__button--more {

	&:after {
		background-position: -138px 0;
		width: 12px;
		height: 12px;
	}
}

.atb__quantity__widget__value {
	height: 34px;
	width: 45px;
	display: inline-block;
	padding: 0;
	margin: 0;
	border: 0;
	text-align: center;
	-moz-appearance: textfield;
	line-height: normal;
	border-radius: 0;
	border-top: 1px solid #d7d7d7;
	border-bottom: 1px solid #d7d7d7;

	.atb__quantity--large & {
		height: 44px;
		width: 85px;
	}

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.atb__icon {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	margin-right: 2px;
}

.atb__icon--tick {
	color: $colorGreen;
}

/* Mobile tweaks */

@include mq-max($bpTablet) {
	.atb__main__list__item {
		display: block;
		width: 100%;
	}

	.atb__main__list--customers {
		width: auto !important;
	}

	.atb__main__list-wrapper--customers {
		width: calc(100% - 20px);
	}

	.atb__main__footer__close,
	.atb__main__footer__basket__button {
		font-size: 14px;
	}

	.atb__main__footer__close {
		padding: 10px;
	}

	.atb__main__footer__basket__button {
		padding: 10px 39px 10px 10px;
	}
}
