﻿@import "../config";

.m-pager
{
	margin: 0;
	padding: 0;
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: -5px 0 0 -5px;
		padding: 0;
		&__item
		{
			@include box-sizing();
			color: $colorGrey;
			display: none;
			font-family: $fontBold;
			@include font-size($fontSize - 1);
			font-weight: normal;
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 5px 0 0 5px;
			vertical-align: top;
			@include mq-min($bpDesktop)
			{
				display: inline-block;
			}
			&--prev, &--next
			{
				display: inline-block;
			}
		}
		&__link
		{
			background: $colorWhite;
			border: 1px solid $colorDivider;
			color: $colorGrey;
			display: block;
			margin: 0;
			padding: 8px 10px;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			min-width: 20px;
			&:hover, &.active
			{
				background: rgba($colorPrimary, .05);
				border-color: $colorPrimary;
				color: $colorPrimary;
			}
			&.disabled
			{
				background: $colorWhite !important;
				border-color: $colorDivider !important;
				color: $colorDivider !important;
				cursor: not-allowed;
				@include opacity(.5);
			}
		}
		&__range
		{
			background: $colorWhite;
			border: 1px solid $colorWhite;
			display: block;
			margin: 0;
			padding: 8px;
			text-align: center;
		}
	}
}