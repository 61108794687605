.c-paypal-info {

	.m-product & {
		padding: 5px 0 15px 0;
	}

	.m-basket & {
		padding: 15px 0 0 0;
	}

	#paypal-form & {
		padding: 15px 0 0 0;
	}
}


.c-paypal-info__copy {
	margin: 0;
	font-size: 12px;
	line-height: 15.6px;
	font-family: Helvetica, sans-serif;
	color: #2c2e2f;
	display: inline-block;
	padding: 10px 0 0 0;
	border-top: 1px solid $colorBorder;

	.m-basket & {
		display: block;
		text-align: center;
		margin: 5px 0 0 0;
	}

	#paypal-form & {
		display: block;
		text-align: center;
	}
}
