@import "../config";

.c-image {
  position: relative;
  @include ratio(16,9);
  display: block;
  overflow: hidden;
  width: 100%;
  width: 100%;

  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: block;
  }
}

.c-image--product {
  @include ratio($ratioImageProductWidth, $ratioImageProductHeight);
}

.c-image--product-pager {
  @include ratio(145,110);
}
