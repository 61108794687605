$cssversion: 8;

@font-face {
	font-family: 'CAMCRegularFont';
	src: url('/fonts/camc/755ca542-6308-44ea-896f-f7c5dfdfb3aa.eot'); /* IE9 Compat Modes */
	src: url('/fonts/camc/755ca542-6308-44ea-896f-f7c5dfdfb3aa.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/fonts/camc/755ca542-6308-44ea-896f-f7c5dfdfb3aa.woff') format('woff'), /* Pretty Modern Browsers */
	url('/fonts/camc/755ca542-6308-44ea-896f-f7c5dfdfb3aa.otf') format('opentype'), /* Safari, Android, iOS */
	url('/fonts/camc/755ca542-6308-44ea-896f-f7c5dfdfb3aa.svg#svgCAMCRegularFont') format('svg'); /* Legacy iOS */
	font-display: swap;
}

@font-face {
	font-family: 'CAMCLightFont';
	src: url('/fonts/camc/f24f178a-6797-4952-972e-edf5e895e8c6.eot'); /* IE9 Compat Modes */
	src: url('/fonts/camc/f24f178a-6797-4952-972e-edf5e895e8c6.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/fonts/camc/f24f178a-6797-4952-972e-edf5e895e8c6.woff') format('woff'), /* Pretty Modern Browsers */
	url('/fonts/camc/f24f178a-6797-4952-972e-edf5e895e8c6.otf') format('opentype'), /* Safari, Android, iOS */
	url('/fonts/camc/f24f178a-6797-4952-972e-edf5e895e8c6.svg#svgCAMCLightFont') format('svg'); /* Legacy iOS */
	font-display: swap;
}

@font-face {
	font-family: 'CAMCBoldFont';
	src: url('/fonts/camc/db07ec36-d56c-40f3-a30b-c2ebaa8e0066.eot'); /* IE9 Compat Modes */
	src: url('/fonts/camc/db07ec36-d56c-40f3-a30b-c2ebaa8e0066.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/fonts/camc/db07ec36-d56c-40f3-a30b-c2ebaa8e0066.woff') format('woff'), /* Pretty Modern Browsers */
	url('/fonts/camc/db07ec36-d56c-40f3-a30b-c2ebaa8e0066.otf') format('opentype'), /* Safari, Android, iOS */
	url('/fonts/camc/db07ec36-d56c-40f3-a30b-c2ebaa8e0066.svg#svgCAMCBoldFont') format('svg'); /* Legacy iOS */
	font-display: swap;
}

//@font-face {
//  font-family: 'CAMCHeavyFont';
//  src: url('/fonts/863721d7-e7f9-4989-9e6c-d616d680a7a0.eot'); /* IE9 Compat Modes */
//  src: url('/fonts/863721d7-e7f9-4989-9e6c-d616d680a7a0.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('/fonts/863721d7-e7f9-4989-9e6c-d616d680a7a0.woff') format('woff'), /* Pretty Modern Browsers */
//       url('/fonts/863721d7-e7f9-4989-9e6c-d616d680a7a0.otf')  format('opentype'), /* Safari, Android, iOS */
//       url('/fonts/863721d7-e7f9-4989-9e6c-d616d680a7a0.svg#svgCAMCHeavyFont') format('svg'); /* Legacy iOS */
//}

@import "base/config";

// colours
$colorBase: #4c4c4c;
$colorBaseHover: #5d5d5d;
$colorOffWhite: #f1f1f1;
$colorRed: #b31f29;
$colorGreen: #5e9949;
$colorYellow: #d25d15;

// 50 shades
$colorGrey: #4b4b4b;
$colorGreyMid: #8b8b8b;
$colorGreyLight: #ababab;
$colorDivider: #ddd;
$colorBorder: #ddd;

// custom
$colorPrimary: #d25d15; // orange
$colorPrimaryHover: #e36e26; // light orange
$colorSecondary: #227374; // teal
$colorSecondaryHover: #338485; // light teal
$colorSecondaryLight: #4b938e; // mint
$colorTertiary: #b31f29; // pink
$colorTertiaryHover: #c4303a; // light pink

// links
$colorLink: $colorPrimary;
$colorLinkHover: $colorBase;

// fonts
$fontSimple: Helvetica, Arial, Verdana, sans-serif;
$fontBase: 'CAMCRegularFont', $fontSimple;
$fontLight: 'CAMCLightFont', $fontBase;
$fontBold: 'CAMCBoldFont', $fontBase;
$fontHeavy: 'CAMCHeavyFont', $fontBold;
$fontSize: 14px;
$fontHeight: 22px;

// responsive breakpoints
$maxPageWidth: 1270px;
$maxContentWidth: 800px;
$bpMobileLarge: 500px; // for 480px
$bpTablet: 620px; // for 600px
$bpTabletLarge: 788px; // for 768px
$bpDesktop: 980px; // for 960px;
$bpDesktopLarge: 1100px; // for 1080px
$bpLarge: $maxPageWidth + 20;

// Section

$cSectionTitleBorderColor: #ddd;

// Recommended Extras

$cRecommendedExtrasImageBorderColor: #dedede;
$cRecommendedExtrasImageBorderHoverColor: $colorPrimary;
$cRecommendExtrasTitleColor: #4c4c4c;
$cRecommendExtrasTitleHoverColor: $colorPrimary;
$cRecommendExtrasTitleBorderColor: #ddd;
$cRecommendExtrasPriceColor: $colorSecondary;
$cRecommendExtrasMembersPriceColor: $colorPrimary;
$cRecommendExtrasPriceWasColor: #4c4c4c;
$cRecommendExtrasPriceSaleColor: $colorRed;
$cRecommendExtrasStatusMessageColor: #7c7c7c;
$cRecommendExtrasScrollbarColor: #4c4c4c;
$cRecommendExtrasScrollbarTrackColor: #e1e1e1;

// Quantity

$cQuantityButtonBackgroundColor: $colorPrimary;
$cQuantityButtonBackgroundHoverColor: darken($colorPrimary, 20%);
$cQuantityInputBorderColor: #d7d7d7;

// Add to basket

$mAtbSidePanelWidth: 380px;
$mAtbSidePanelWidthTablet: 325px;
$mAtbPanelWidth: 860px;
$mAtbDesktopBp: 990px;
$mAtbWideBp: ($mAtbSidePanelWidth + $mAtbPanelWidth); // 1235
$mAtbDeepBp: 628px; // iphone 6/7/8 Plus - https://www.kylejlarson.com/blog/iphone-6-screen-size-web-design-tips/
$mAtbAnimationTime: 300ms;
$mAtbAnimationEasing: ease;
$mAtbHeaderHeight: 40px;
$mAtbHeaderHeightDeep: 100px;
$mAtbFooterHeight: 90px;
$mAtbFooterHeightDesktop: 90px;
$mAtbColorSuccess: #08a500;
$mAtbColorTitle: #333;
$mAtbColorDelivery: #333;
$mAtbColorPrice: #333;
$mAtbColorFilterBy: #333;
$mAtbColorBlue: #00abee;
$mAtbLoaderColor: $colorPrimary;
$mAtbSaleColor: $colorRed;

// Ymal

$csmall-productPriceColor: $colorSecondary;
$csmall-productMembersPriceColor: $colorPrimary;

// Shared Ratios

$ratioImageProductWidth: 840;
$ratioImageProductHeight: 750;
