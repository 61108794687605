﻿@import "../config";

.m-checkout-steps
{
	margin: 0 -10px;
	padding: 15px 0;
	position: relative;
	text-align: center;
	z-index: 1;
	&:before
	{
		background: $colorDivider;
		content: "";
		display: block;
		height: 1px;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 29px;
		width: 100%;
		z-index: 1;
	}
	&__inner
	{
		margin: 0 auto;
		padding: 0;
		max-width: 960px;
	}
	&__menu
	{
		counter-reset: ol-steps;
		font-size: 0;
		line-height: 0;
		margin: -5px 0 0 -5px;
		padding: 0;
		position: relative;
		z-index: 2;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize - 3);
			@include line-height($fontHeight  - 3);
			margin: 0;
			padding: 5px 0 0 5px;
			text-transform: uppercase;
			width: 100%;
			max-width: 33.3333%;
		}
		&__link
		{
			color: $colorGreyLight;
			display: block;
			cursor: default;
			margin: 0 auto;
			padding: 45px 0 0 0;
			position: relative;
			max-width: 90px;
			z-index: 1;
			&:before
			{
				background: $colorWhite;
				border: 1px solid $colorDivider;
				@include border-radius(100%);
				@include box-sizing();
				color: $colorGreyLight;
				content: counter(ol-steps);
				counter-increment: ol-steps;
				display: block;
				font-family: $fontBold;
				@include font-size($fontSize + 5);
				font-weight: normal;
				height: 50px;
				left: 50%;
				@include line-height($fontHeight + 5);
				margin: 0 0 0 -25px;
				padding: 11px 0 0 0;
				position: absolute;
				text-align: center;
				top: -11px;
				width: 50px;
				z-index: 1;
			}
			&:hover
			{
				color: $colorGreyLight;
			}
			&.done
			{
				color: $colorGrey;
				cursor: pointer;
				&:before
				{
					background: lighten($colorDivider, 10%);
					color: $colorGrey;
				}
				&:hover
				{
					color: $colorBase;
					&:before
					{
						color: $colorBase;
					}
				}
			}
			&.active
			{
				color: $colorGrey;
				&:before
				{
					background: lighten($colorDivider, 10%);
					color: $colorBase;
				}
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 30px;
	}
}