@import "../config";

// visibility
.offscreen
{
	height: 0;
  left: -9999px;
  margin: 0;
  padding: 0;
  position: absolute;
	@include text-hidden();
  width: 0;
  z-index: -1;
}

.hide
{
	display: none;
}

.hide-on-mobile
{
	@include mq-max($bpMobileLarge - 1)
	{
		display: none;
	}
}

.hide-on-mobile-large
{
	@include mq-min($bpMobileLarge)
	{
		@include mq-max($bpTablet - 1)
		{
			display: none;
		}
	}	
}

.hide-on-mobile-large-or-smaller
{
	@include mq-max($bpMobileLarge - 1)
	{
		display: none;
	}
}

.hide-on-mobile-large-or-bigger
{
	@include mq-min($bpMobileLarge)
	{
		display: none;
	}
}

.hide-on-tablet
{
	@include mq-min($bpTablet)
	{
		@include mq-max($bpTabletLarge - 1)
		{
			display: none;
		}
	}	
}

.hide-on-tablet-or-smaller
{
	@include mq-max($bpTablet - 1)
	{
		display: none;
	}
}

.hide-on-tablet-or-bigger
{
	@include mq-min($bpTablet)
	{
		display: none;
	}
}

.hide-on-tablet-large
{
	@include mq-min($bpTabletLarge)
	{
		@include mq-max($bpDesktop - 1)
		{
			display: none;
		}
	}	
}

.hide-on-tablet-large-or-smaller
{
	@include mq-max($bpTabletLarge - 1)
	{
		display: none;
	}
}

.hide-on-tablet-large-or-bigger
{
	@include mq-min($bpTabletLarge)
	{
		display: none;
	}
}

.hide-on-desktop
{
	@include mq-min($bpDesktop)
	{
		@include mq-max($bpDesktopLarge - 1)
		{
			display: none;
		}
	}
}

.hide-on-desktop-or-smaller
{
	@include mq-max($bpDesktop - 1)
	{
		display: none;
	}
}

.hide-on-desktop-or-bigger
{
	@include mq-min($bpDesktop)
	{
		display: none;
	}
}

.hide-on-desktop-large
{
	@include mq-min($bpDesktopLarge)
	{
		@include mq-max($bpLarge - 1)
		{
			display: none;
		}
	}
}

.hide-on-desktop-large-or-smaller
{
	@include mq-max($bpDesktopLarge - 1)
	{
		display: none;
	}
}

.hide-on-desktop-large-or-bigger
{
	@include mq-min($bpDesktopLarge)
	{
		display: none;
	}
}

.hide-on-large
{
	@include mq-min($bpLarge)
	{
		display: none;
	}
}

// js
.hide-on-js
{
	.js & 
	{
		display: none;
	}
}

.show-on-js
{
	.no-js &
	{
		display: none;
	}
}

// alignment
.align-center
{
	text-align: center;
}

.align-right
{
	text-align: right;
}

.align-justify
{
	text-align: justify;
}

// spacing
.spaced
{
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 5px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 10px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 15px;
	}
}

// dividers
.divider
{
	border-bottom: 1px solid $colorDivider;
	margin-bottom: 15px;
	padding-bottom: 15px;
	&--dashed
	{
		border-bottom-style: dashed;
	}
	&--dotted
	{
		border-bottom-style: dotted;
	}
}

// colors
.highlight-red
{
	color: $colorRed;
}

.highlight-orange
{
	color: $colorYellow;
}

.highlight-green
{
	color: $colorGreen;
}