﻿@import "../config";

.m-swatch
{
	display: none;
	margin: 0;
	padding: 5px 0;
	max-width: 400px;
	&__menu
	{
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: -5px 0 0 -5px;
		padding: 0;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			list-style: none;
			margin: 0;
			padding: 5px 0 0 5px;
			width: 65px;
		}
		&__hex
		{
      border: 3px solid $colorWhite;
      @include box-sizing();
			cursor: pointer;
			display: block;
			height: 100%;
			margin: 0;
			padding: 0;
			position: relative;
			height: 60px;
			width: 60px;
			z-index: 1;
			-webkit-appearance: none;
		    -moz-appearance: none;
		    appearance: none;
			&:before, &:after
			{
				bottom: -3px;
				content: "";
				display: none;
				left: -3px;
				margin: 0;
				padding: 0;
				position: absolute;
				right: -3px;
				top: -3px;
			}
			&:before
			{
				border: 1px solid $colorDivider;
				display: block;
				z-index: 3;
			}
			&:after
			{
				background: $colorWhite;
				border: 0;
				bottom: auto;
				height: 78px;
				left: 50%;
				margin: -39px 0 0 0;
				right: auto;
				@include rotate(45deg);
				top: 50%;
				@include transform-origin(50% 50%);
				width: 3px;
        z-index: 2;
			}
			&:hover, &.active
			{
				&:before
				{
					border-color: $colorGrey;
				}
			}
			&.unavailable
			{
        @include opacity(0.5);
				&:after
				{
					display: block;
				}
			}
      &.active.unavailable
      {
        &:before, &:after
        {
          display: block;
        }
      }
		}
	}
	&__selected
	{
		color: $colorGrey;
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 5px 0 0 0;
	}
	.js &
	{
		display: block;
	}
}