﻿@import "../config";

.m-side-nav
{
	position: relative;
	margin: 0;
	padding: 0 0 15px 0;
	&__title
	{
		margin: 0;
		padding: 0;
		&__link
		{
			background: $colorOffWhite;
			color: $colorBase;
			display: block;
			font-family: $fontBold;
			@include font-size($fontSize);
			font-weight: normal;
			@include line-height($fontHeight);
			margin: 0;
			padding: 10px 25px 10px 10px;
			position: relative;
			text-decoration: none;
			z-index: 1;
			&:before
			{
				border: 5px solid transparent;
				border-bottom-width: 0;
				border-top-color: $colorBase;
				content: "";
				display: block;
				height: 0;
				margin: -3px 0 0 0;
				padding: 0;
				position: absolute;
				right: 10px;
				top: 50%;
				width: 0;
				z-index: 1;
			}
			&:hover
			{
				background: darken($colorOffWhite, 5%);
				color: $colorBase;
				text-decoration: none;
				&:before
				{
					border-top-color: $colorBase;
				}
			}
			&.active
			{
				background: darken($colorOffWhite, 10%);
				&:before
				{
					border-bottom-color: $colorBase;
					border-bottom-width: 5px;
					border-top-color: transparent;
					border-top-width: 0;
				}
			}
		}
		@include mq-min($bpTabletLarge)
		{
			display: none !important;
		}
	}
	&__inner
	{
		background: lighten($colorDivider, 10%);
		margin: 0;
		padding: 0;
		.js &
		{
			display: none;
			@include mq-min($bpTabletLarge)
			{
				display: block !important;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			background: none;
		}
	}
	&__section
	{
		margin: 0;
		padding: 0;
		&__link
		{
			color: $colorBase;
			display: block;
			font-family: $fontBold;
			font-weight: normal;
			margin: 0;
			padding: 10px;
			text-decoration: none;
			&:hover
			{
				background: darken($colorOffWhite, 5%);
				color: $colorBase;
				text-decoration: none;
				@include mq-min($bpTabletLarge)
				{
					background: none;
					color: $colorPrimary;
				}
			}
			@include mq-min($bpTabletLarge)
			{
				@include font-size($fontSize + 4);
				@include line-height($fontHeight + 4);
				padding: 5px 0;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			padding-bottom: 5px;
		}
		@include mq-min($bpDesktop)
		{
			padding-bottom: 10px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-bottom: 15px;
		}
	}
	&__menu
	{
		margin: 0;
		padding: 0;
		&__item
		{
			border-top: 1px solid $colorWhite;
			margin: 0;
			padding: 0;
			@include mq-min($bpTabletLarge)
			{
				border-bottom: 1px solid $colorDivider;
				border-top: 0;
			}
		}
		&__link
		{
			color: $colorBase;
			display: block;
			font-family: $fontBold;
			margin: 0;
			padding: 10px 10px 10px 25px;
			position: relative;
			text-decoration: none;
			z-index: 1;
			&:before
			{
				background: $colorGreyMid;
				content: "";
				display: block;
				height: 1px;
				left: 12px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 19px;
				width: 5px;
				z-index: 1;
				@include mq-min($bpTabletLarge)
				{
					display: none;
				}
			}
			&:hover, &.active
			{
				background: darken($colorOffWhite, 5%);
				color: $colorBase;
				text-decoration: none;
				&:before
				{
					background: $colorBase;
				}
				@include mq-min($bpTabletLarge)
				{
					background: none;
					color: $colorPrimary;
				}
			}
			@include mq-min($bpTabletLarge)
			{
				padding: 10px 0;
			}
			@include mq-min($bpDesktop)
			{
				padding: 10px;
			}
		}
	}
	&__sub-menu
	{
		margin: 0;
		padding: 0 0 15px 0;
		&__item
		{
			margin: 0;
			padding: 0;
		}
		&__link
		{
			color: $colorGrey;
			display: block;
			font-family: $fontBase;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 5px 10px 5px 25px;
			position: relative;
			text-decoration: none;
			z-index: 1;
			&:hover, &.active
			{
				color: $colorPrimary;
				text-decoration: none;
			}
		}
	}
	&__reveal
	{
		margin: 0;
		padding: 0;
		position: relative;
		@include transition(max-height .5s);
		z-index: 1;
		&__action
		{
			display: none;
			margin: 0;
			padding: 10px 0 0 0;
			&__link
			{
				display: inline-block;
				font-family: $fontBold;
				@include font-size($fontSize - 1);
				font-weight: normal;
				@include line-height($fontHeight - 1);
				margin: 0;
				padding: 0;
				&:after
				{
					content: " +";
					font-family: $fontSimple;
					@include font-size($fontSize);
					font-weight: normal;
				}
			}
			@include mq-min($bpDesktop)
			{
				padding: 10px 10px 0 10px;
			}
			.js &
			{
				@include mq-min($bpTabletLarge)
				{
					display: block;
				}
			}
		}
		.js &
		{
			@include mq-min($bpTabletLarge)
			{
				overflow: hidden;
				max-height: 205px;
			}
		}
		&.active
		{
			@include mq-min($bpTabletLarge)
			{
				max-height: 1000px;
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 30px;
	}
	&:last-child, &--tight
	{
		padding-bottom: 0;
	}
	&--divider
	{
		@include mq-min($bpTabletLarge)
		{
			border-bottom: 1px solid $colorDivider;
			margin-bottom: 15px;
			padding-bottom: 15px;
		}
	}
}