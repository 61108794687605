﻿@import "../config";

$bpSwitch: $bpTabletLarge;

.m-banner
{
	background: $colorOffWhite;
	margin: 0 -10px;
	padding: 0;
	&__inner
	{
		margin: 0 auto;
		padding: 0;
		position: relative;
		max-width: 760px;
		max-width: $maxPageWidth;
		z-index: 1;
		@include mq-min($bpSwitch)
		{
			max-width: $maxPageWidth;
		}
	}
	&__menu
	{
		height: 100%;
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
		&__item
		{
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;
			&--1
			{
				@include mq-min($bpSwitch)
				{
					width: 66.6666%;
				}
			}
			&--2, &--3
			{
				@include mq-min($bpSwitch)
				{
					border: 2px solid $colorWhite;
					border-right: 0;
					position: absolute;
					right: 0;
					width: 33.3333%;
					z-index: 2;
				}
			}
			&--2
			{
				@include mq-min($bpSwitch)
				{
					border-top: 0;
					top: -1px;
				}
			}
			&--3
			{
				@include mq-min($bpSwitch)
				{
					border-bottom: 0;
					bottom: -1px;
				}
			}
		}
		@include mq-min($bpSwitch)
		{
			overflow: hidden;
		}
	}
	&__block
	{
		background: $colorBase;
		margin: 0;
		padding: 0;
		&__image
		{
			display: block;
			height: auto;
			margin: 0;
			padding: 0;
			position: relative;
			width: auto;
			z-index: 1;
			@include mq-min($bpSwitch)
			{
				max-height: 570px;
			}
		}
		&__content
		{
			@include box-sizing();
			color: $colorWhite;
			left: 0;
			margin: 0;
			padding: 15px 45px 35px 45px;
			position: absolute;
			text-align: left;
			@include text-shadow(1px 1px 2px rgba($colorBlack, .8));
			top: 0;
			max-width: 450px;
			z-index: 2;
			@include mq-min($bpTablet)
			{
				padding: 25px 55px 45px 55px;
			}
			@include mq-min($bpSwitch)
			{
				padding: 15px;
			}
			@include mq-min($bpDesktop)
			{
				padding: 25px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding: 35px;
			}
			@include mq-min($bpLarge)
			{
				padding: 40px 45px;
			}
			&--dark
			{
				color: $colorBase;
				@include text-shadow(none);
			}
			&--top-right
			{
				left: auto;
				right: 0;
				text-align: right;
			}
			&--bottom-left
			{
				bottom: 0;
				top: auto;
			}
			&--bottom-right
			{
				bottom: 0;
				left: auto;
				top: auto;
				right: 0;
				text-align: right;
			}
		}
		&__title, &__description, &__price
		{
			font-family: $fontBase;
			margin: 0;
			padding: 0;
		}
		&__title
		{
			font-family: $fontBase;
			@include font-size($fontSize + 5);
			@include line-height($fontHeight + 5);
			@include mq-min($bpDesktopLarge)
			{
				@include font-size($fontSize + 10);
				@include line-height($fontHeight + 10);
			}
			.m-banner__menu__item--1 &
			{
				@include mq-min($bpSwitch)
				{
					@include font-size($fontSize + 10);
					@include line-height($fontHeight + 10);
				}
				@include mq-min($bpDesktop)
				{
					@include font-size($fontSize + 15);
					@include line-height($fontHeight + 15);
				}
				@include mq-min($bpDesktopLarge)
				{
					@include font-size($fontSize + 20);
					@include line-height($fontHeight + 20);
				}
				@include mq-min($bpLarge)
				{
					@include font-size($fontSize + 25);
					@include line-height($fontHeight + 25);
				}
			}
		}
		&__description
		{
			@include font-size($fontSize + 2);
			@include line-height($fontHeight + 2);
			@include mq-min($bpDesktopLarge)
			{
				@include font-size($fontSize + 4);
				@include line-height($fontHeight + 4);
			}
			.m-banner__menu__item--1 &
			{
				@include mq-min($bpSwitch)
				{
					@include font-size($fontSize + 4);
					@include line-height($fontHeight + 4);
				}
				@include mq-min($bpDesktopLarge)
				{
					@include font-size($fontSize + 6);
					@include line-height($fontHeight + 6);
				}
				@include mq-min($bpLarge)
				{
					@include font-size($fontSize + 8);
					@include line-height($fontHeight + 8);
				}
			}
		}
		&__price
		{
			display: inline-block;
			font-family: $fontBold;
			@include font-size($fontSize + 2);
			@include line-height($fontHeight + 2);
			margin: 0;
			padding: 10px 15px 10px 0;
			vertical-align: middle;
			.m-banner__menu__item--1 &
			{
				@include mq-min($bpDesktop)
				{
					@include font-size($fontSize + 4);
					@include line-height($fontHeight + 4);
				}
			}
		}
		&__action
		{
			margin: 0;
			padding: 15px 0 0 0;
			.form-button
			{
				@include box-shadow(1px 1px 1px rgba($colorBlack, .25));
				&--clear--dark
				{
					@include box-shadow(none);
				}
			}
			@include mq-min($bpSwitch)
			{
				display: none;
			}
			@include mq-min($bpDesktop)
			{
				display: block;
			}
			.m-banner__menu__item--1 &
			{
				@include mq-min($bpDesktop)
				{
					padding-top: 20px;
				}
			}
		}
		&__link
		{
			bottom: 0;
			display: block;
			left: 45px;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 45px;
			@include text-hidden();
			top: 0;
			z-index: 5;

			&.focus-visible {
				@include focus-style-alt($colorPrimary, 6px);
			}
		}
		&:hover
		{
			.m-banner__block__action
			{
				.form-button
				{
					background: $colorWhite;
					border-color: $colorWhite;
					color: $colorBase;
					@include text-shadow(none);
					&--clear--dark
					{
						background: $colorGrey;
						border-color: $colorBase;
						color: $colorWhite;
					}
				}
			}			
		}
	}
	.bx-pager
	{
		display: none;
	}
}