﻿.c-dispatch-date {
	margin: 0;
	padding: 0 0 0 40px;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position: 0 -4px;
	min-height: 30px;
	font-size: 13px;

	a {
		color: $colorPrimary;
		text-decoration: underline;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}

.c-dispatch-date--in {
	//background-image: url(../images/icons/dispatch-in.svg);
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100.06 100.06'%3E%3Cg fill='%2377a941'%3E%3Cpath d='M94 45.92L88.57 43a4.63 4.63 0 01-2.1-2.33l-3.33-8.09a8.05 8.05 0 00-7.47-5H30.58a8.09 8.09 0 00-8.07 8.08V62a8.08 8.08 0 008.07 8.08h3a8.78 8.78 0 0016.2 0h20.39a8.77 8.77 0 0016.2 0h3.83A8.08 8.08 0 0098.27 62v-9A8.06 8.06 0 0094 45.92zM41.68 72.06A5.37 5.37 0 1147 66.69a5.38 5.38 0 01-5.32 5.37zm36.59 0a5.37 5.37 0 115.37-5.37 5.37 5.37 0 01-5.37 5.37zM94.87 62a4.68 4.68 0 01-4.67 4.67h-2.74a9.19 9.19 0 00-18.38 0H50.86a9.19 9.19 0 00-18.37 0h-1.91A4.68 4.68 0 0125.91 62V35.64A4.68 4.68 0 0130.58 31h45.09A4.66 4.66 0 0180 33.86L83.32 42A8.08 8.08 0 0087 46l5.45 2.94A4.66 4.66 0 0194.87 53z'/%3E%3Cpath d='M2.36 40.75h29.95v3.41H2.36zM9.1 50.2h27.75v3.41H9.1z'/%3E%3C/g%3E%3C/svg%3E");
}

.c-dispatch-date--order {
	//background-image: url(../images/icons/dispatch-order.svg);
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100.06 100.06'%3E%3Cg fill='%23fcc10f'%3E%3Cpath d='M86.39 45.78l-5.46-2.94a4.63 4.63 0 01-2.1-2.33l-3.33-8.09a8 8 0 00-7.47-5H22.94a8.09 8.09 0 00-8.07 8.08v26.34a8.08 8.08 0 008.07 8.08h3a8.78 8.78 0 0016.2 0h20.4a8.77 8.77 0 0016.19 0h3.83a8.08 8.08 0 008.07-8.08v-9a8.06 8.06 0 00-4.24-7.06zM34 71.92a5.37 5.37 0 115.37-5.37A5.38 5.38 0 0134 71.92zm36.59 0A5.37 5.37 0 1176 66.55a5.37 5.37 0 01-5.37 5.37zm16.6-10.08a4.67 4.67 0 01-4.67 4.67h-2.7a9.19 9.19 0 00-18.37 0H43.23a9.19 9.19 0 00-18.38 0h-1.91a4.68 4.68 0 01-4.67-4.67V35.5a4.68 4.68 0 014.67-4.67H68a4.66 4.66 0 014.32 2.89l3.33 8.09a8.08 8.08 0 003.64 4l5.45 2.94a4.66 4.66 0 012.46 4.11z' /%3E%3C/g%3E%3C/svg%3E");
}

.c-dispatch-date--unavailable {
	//background-image: url(../images/icons/dispatch-unavailable.svg);
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100.06 100.06'%3E%3Cg fill='%239e9e9e'%3E%3Cpath d='M86.39 45.78l-5.46-2.94a4.63 4.63 0 01-2.1-2.33l-3.33-8.09a8 8 0 00-7.47-5H22.94a8.09 8.09 0 00-8.07 8.08v26.34a8.08 8.08 0 008.07 8.08h3a8.78 8.78 0 0016.2 0h20.4a8.77 8.77 0 0016.19 0h3.83a8.08 8.08 0 008.07-8.08v-9a8.06 8.06 0 00-4.24-7.06zM34 71.92a5.37 5.37 0 115.37-5.37A5.38 5.38 0 0134 71.92zm36.59 0A5.37 5.37 0 1176 66.55a5.37 5.37 0 01-5.37 5.37zm16.6-10.08a4.67 4.67 0 01-4.67 4.67h-2.7a9.19 9.19 0 00-18.37 0H43.23a9.19 9.19 0 00-18.38 0h-1.91a4.68 4.68 0 01-4.67-4.67V35.5a4.68 4.68 0 014.67-4.67H68a4.66 4.66 0 014.32 2.89l3.33 8.09a8.08 8.08 0 003.64 4l5.45 2.94a4.66 4.66 0 012.46 4.11z' /%3E%3C/g%3E%3C/svg%3E");
}

.c-dispatch-date--small {
	background-size: 25px 25px;
	background-position: 0 -3px;
	min-height: 25px;
	padding: 0 0 0 35px;
	font-size: 12px;
	line-height: 17px;
}
