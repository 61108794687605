﻿@import "../config";

.m-refine
{
	@include font-size($fontSize - 1);
	@include line-height($fontHeight - 1);
	margin: 0;
	padding: 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		color: $colorBase;
		display: block;
		font-family: $fontBold;
		@include font-size($fontSize + 4);
		font-weight: normal;
		@include line-height($fontHeight + 4);
		margin: 0;
		padding: 5px 0 15px 0;
		position: relative;
		text-decoration: none;
		z-index: 1;
		&:before
		{
			border: 5px solid transparent;
			border-bottom-width: 0;
			border-top-color: $colorBase;
			content: "";
			display: block;
			height: 0;
			margin: -3px 0 0 0;
			padding: 0;
			position: absolute;
			right: 10px;
			top: 50%;
			width: 0;
			z-index: 1;
			@include mq-min($bpTabletLarge)
			{
				display: none;
			}
		}
		&:hover
		{
			@include mq-max($bpTabletLarge - 1)
			{
				background: $colorDivider;
				text-decoration: none;
			}
		}
		&.active
		{
			&:before
			{
				border-bottom-color: $colorBase;
				border-bottom-width: 5px;
				border-top-color: transparent;
				border-top-width: 0;
			}
			@include mq-max($bpTabletLarge - 1)
			{
				background: $colorDivider;
				text-decoration: none;
			}
		}
		@include mq-max($bpTabletLarge - 1)
		{
			background: $colorOffWhite;
			cursor: pointer;
			@include font-size($fontSize);
			font-weight: normal;
			@include line-height($fontHeight);
			padding: 10px 25px 10px 10px;
		}
		@include mq-min($bpDesktop)
		{
			padding-bottom: 20px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-bottom: 25px;
		}
	}
	&__content
	{
		margin: 0;
		padding: 0;
		&__inner
		{
			background: $colorOffWhite;
			border-top: 1px solid $colorWhite;
			margin: 0;
			padding: 10px;
			@include mq-min($bpTabletLarge)
			{
				background: none;
				border: 0;
				padding: 0;
			}
		}
		.js &
		{
			display: none;
			@include mq-min($bpTabletLarge)
			{
				display: block !important;
			}
		}
	}
	&__clear
	{
		background: $colorOffWhite;
		border-top: 1px solid $colorWhite;
		margin: 0;
		padding: 10px;
		&__link
		{
			color: $colorBase;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			text-decoration: none;
			&:hover
			{
				color: $colorBase;
				text-decoration: underline;
				@include mq-min($bpTabletLarge)
				{
					color: $colorPrimary;
					text-decoration: underline;
				}
			}
			@include mq-min($bpTabletLarge)
			{
				background: none;
				border-top-color: $colorDivider;
				color: $colorPrimary;
				font-family: $fontBold;
				font-weight: normal;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			background: none;
			border-top-color: $colorDivider;
			padding: 15px 0;
		}
	}
}