@import "../config";

.m-flash {
	@include bg('/images/camc/common/sprite.png', no-repeat, 0 -288px);
	display: none;
	height: 74px;
	left: 0;
	margin: 0;
	padding: 0;
	position: absolute;
	@include text-hidden();
	top: 0;
	width: 74px;
	z-index: 100;

	&--site-exclusive, &--exlusive, &--sale, &--preorder, &--made-to-order {
		display: block;
	}

	&--site-exclusive, &--exlusive {
		background-position-x: 0;
	}

	&--sale {
		background-position-x: -79px;
	}

	&--preorder {
		background-position-x: -163px;
	}

	&--made-to-order {
		background-position-x: -246px;
	}
}
