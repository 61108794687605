@import "../config";

.l-nav {
	background: $colorDivider;
	margin: 0 -10px;
	padding: 0;
	position: relative;
	z-index: 8888;

	&__inner {
		@include box-sizing();
		margin: 0 auto;
		padding: 0;
		position: relative;
		text-align: left;
		max-width: $maxPageWidth;
		z-index: 1;
	}

	&__title {
		border-left: 1px solid $colorDivider;
		display: none;
		height: 54px;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		top: -54px;
		width: 54px;
		z-index: 3;

		&__link {
			//background: yellow;
			display: block;
			height: 100%;
			margin: 0;
			padding: 0;
			position: relative;
			@include text-hidden();
			z-index: 1;

			&:before {
				@include bg("/images/camc/common/sprite.png", no-repeat, -154px -3px);
				content: "";
				display: block;
				height: 15px;
				left: 18px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 19px;
				width: 24px;
				z-index: 1;

				@include mq-min($bpTablet) {
					left: 21px;
					top: 24px;
				}
			}

			&.active {
				background: $colorDivider;

				&:before {
					background-position: -212px -3px;
					height: 14px;
					left: 22px;
					top: 19px;
					width: 14px;

					@include mq-min($bpTablet) {
						left: 25px;
						top: 24px;
					}
				}
			}
		}

		@include mq-min($bpTablet) {
			height: 64px;
			top: -64px;
			width: 64px;
		}

		@include mq-min($bpDesktop) {
			display: none !important;
		}

		.js & {
			display: block;
		}
	}

	&__content {
		//display: none !important;
		@include box-sizing();
		margin: 0;
		padding: 0;

		.js & {
			display: none;
		}

		@include mq-min($bpDesktop) {
			display: block !important;
		}
	}

	&__menu {
		margin: 0;
		padding: 0;

		@include mq-min($bpDesktop) {
			width: calc(100% - 325px);
		}

		@include mq-min($bpDesktopLarge) {
			width: calc(100% - 400px);
		}

		&__item {
			border-top: 1px solid $colorOffWhite;
			margin: 0;
			padding: 0;
			position: relative;

			&.hover {
				z-index: 8888;

				.l-nav__sub {
					@include mq-min($bpDesktop) {
						display: block;
					}
				}
			}

			@include mq-min($bpDesktop) {
				border: 0;
				float: left;
				padding-left: 2px;
			}

			&.with-sub {
				&:hover {
					.l-nav__menu__link {
						@include mq-min($bpDesktop) {
							background: $colorPrimary;
							color: $colorWhite;
							z-index: 4;
						}
					}
				}
			}

			&--divider {
				&:before {
					border-left: 1px solid $colorGreyLight;
					bottom: 15px;
					content: "";
					display: none;
					left: -5px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 15px;
					z-index: 1;

					@include mq-min($bpDesktopLarge) {
						display: block;
					}

					@include mq-min($bpLarge) {
						left: -10px;
					}
				}

				@include mq-min($bpDesktopLarge) {
					margin-left: 10px;
				}

				@include mq-min($bpLarge) {
					margin-left: 20px;
				}

				&--narrow {
					&:before {
						left: -1px;
					}

					@include mq-min($bpDesktopLarge) {
						margin-left: 1px;
					}
				}
			}

			&--full {
				@include mq-min($bpDesktop) {
					position: static;

					.l-nav__sub {
						top: auto;
					}
				}
			}

			&--home {
				@include mq-min($bpDesktop) {
					display: none;
				}
			}
		}

		&__link {
			@include box-sizing();
			color: $colorBase;
			display: block;
			height: 40px;
			font-family: $fontBase;
			@include font-size($fontSize + 1);
			@include line-height($fontHeight + 1);
			margin: 0;
			padding: 10px 40px 10px 10px;
			position: relative;
			text-decoration: none;
			z-index: 3;

			&:before, &:after {
				content: "";
				display: none;
				margin: 0;
				padding: 0;
				position: absolute;
				z-index: 3;
			}

			&:before {
				background: $colorPrimary;
				bottom: -2px;
				height: 2px;
				left: 0;
				right: 0;

				.without-sub & {
					display: none !important;
				}
			}

			&:after {
				border-top: 3px solid $colorPrimary;
				bottom: 0;
				left: 0;
				right: 0;
			}

			&:hover, &.hover, &.active {
				color: $colorBase;
				text-decoration: none;

				&:after {
					@include mq-min($bpDesktop) {
						display: block;
					}
				}

				@include mq-min($bpDesktop) {
					color: $colorBase;
				}

				.with-sub & {
					@include mq-min($bpDesktop) {
						background: $colorPrimary;
						color: $colorWhite;
					}
				}
			}

			&.hover {
				z-index: 10;

				&:before {
					@include mq-min($bpDesktop) {
						display: block;
					}
				}
			}

			&.active {
				color: $colorPrimary;

				&:after {
					@include mq-min($bpDesktop) {
						display: block;
					}
				}

				@include mq-min($bpDesktop) {
					background: none;
					//color: $colorBase;
				}
			}

			@include mq-min($bpDesktop) {
				height: 50px;
				padding: 15px 10px;
				position: relative;
				z-index: 1;
			}

			@include mq-min($bpDesktopLarge) {
				padding: 15px;
			}

			&--alt {
				color: $colorSecondary;
				font-family: $fontBold;
				@include font-size($fontSize - 1);
				@include line-height($fontHeight - 1);

				&:hover, &.active {
					color: $colorSecondary;

					&:after {
						@include mq-min($bpDesktop) {
							display: none;
						}
					}

					@include mq-min($bpDesktop) {
						color: $colorBase;
					}
				}
			}

			&--basket {
				background: $colorSecondaryLight;
				color: $colorWhite;
				display: none;
				font-family: $fontBold;
				@include font-size($fontSize + 1);
				@include line-height($fontHeight + 1);
				padding-left: 53px;
				padding-top: 14px;
				position: relative;
				z-index: 1;

				&:before {
					@include bg("/images/camc/common/sprite.png", no-repeat, 0 -33px);
					content: "";
					display: block;
					height: 20px;
					left: 15px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 10px;
					width: 25px;
					z-index: 1;

					@include mq-min($bpDesktop) {
						left: 20px;
						top: 14px;
					}

					@include mq-min($bpLarge) {
						left: 30px;
					}
				}

				&__count {
					background: $colorWhite;
					@include border-radius(100%);
					@include box-sizing();
					color: $colorSecondary;
					display: block;
					font-family: $fontSimple;
					font-size: 9px;
					font-weight: bold;
					height: 15px;
					line-height: 9px;
					left: 10px;
					margin: 0;
					overflow: hidden;
					padding: 4px 0 0 0;
					position: absolute;
					text-align: center;
					top: 20px;
					width: 15px;
					z-index: 2;

					@include mq-min($bpDesktop) {
						left: 15px;
						top: 25px;
					}

					@include mq-min($bpLarge) {
						left: 25px;
					}
				}

				&:hover, &.active {
					background: $colorSecondary;
					color: $colorWhite;

					&:after {
						display: none;
					}
				}

				@include mq-min($bpDesktop) {
					display: block;
				}

				@include mq-min($bpLarge) {
					@include font-size($fontSize + 2);
					@include line-height($fontHeight + 2);
					padding-left: 70px;
					padding-right: 30px;
					padding-top: 13px;
				}
			}
		}

		&__more {
			border-left: 1px solid $colorWhite;
			cursor: pointer;
			height: 40px;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			@include text-hidden();
			top: 0;
			width: 40px;
			z-index: 100;

			&:before {
				border: 5px solid transparent;
				border-bottom-width: 0;
				border-top-color: $colorBase;
				content: "";
				display: block;
				height: 0;
				left: 50%;
				margin: -3px 0 0 -5px;
				padding: 0;
				position: absolute;
				top: 50%;
				width: 0;
				z-index: 1;
			}

			&:hover, &.active {
				background: lighten($colorDivider, 5%);
			}

			&.active {
				&:before {
					border-bottom-color: $colorBase;
					border-bottom-width: 5px;
					border-top-color: transparent;
					border-top-width: 0;
				}
			}

			@include mq-min($bpDesktop) {
				display: none;
			}
		}

		@include mq-min($bpDesktop) {
			float: left;
			margin-left: -2px;
		}

		&--alt {

			@include mq-min($bpDesktop) {
				float: right;
				width: auto;
			}
		}
	}

	&__sub {
		background: lighten($colorDivider, 5%);
		@include box-sizing();
		display: none;
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 2;

		&__inner {
			margin: 0;
			padding: 0;

			@include mq-min($bpDesktop) {
				text-align: center;
			}
		}

		&__col {
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 0;
			text-align: left;
			vertical-align: top;
			width: 100%;

			&__link {
				//background: orange;
				color: $colorGrey;
				display: block;
				font-family: $fontBase;
				@include font-size($fontSize + 1);
				@include line-height($fontHeight + 1);
				margin: 0;
				padding: 10px;
				text-decoration: none;

				&.active, &:hover {
					color: $colorPrimary;

					@include mq-min($bpDesktop) {
						color: $colorWhite;
						text-decoration: underline;
					}
				}

				&.focus-visible {
					@include focus-style-alt($colorWhite);
				}

				@include mq-min($bpDesktop) {
					color: $colorWhite;
					font-family: $fontBold;
					padding: 8px 10px;
					@include text-overflow();
					text-transform: none;
				}
			}

			@include mq-min($bpDesktop) {
				padding: 0;
			}

			@include mq-min($bpDesktopLarge) {
				padding: 0 20px;
			}

			@include mq-min($bpLarge) {
				padding: 0 40px;
			}

			&--1 {
				@include mq-min($bpDesktop) {
					padding: 0;
				}
			}

			&--2 {
				@include mq-min($bpDesktop) {
					width: 50%;
				}
			}

			&--3 {
				@include mq-min($bpDesktop) {
					width: 33.3333%;
				}
			}

			&--4 {
				@include mq-min($bpDesktop) {
					width: 25%;
				}
			}
		}

		&__menu {
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;

			&:before {
				border-top: 1px solid $colorWhite;
				border-top-color: rgba($colorWhite, .25);
				content: "";
				display: block;
				left: 10px;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 10px;
				top: -1px;
				z-index: 1;
			}

			&__item {
				display: inline-block;
				margin: 0;
				padding: 5px 0 0 0;
				vertical-align: top;
				width: 50%;

				@include mq-min($bpTablet) {
					width: 33.3333%;
				}

				@include mq-min($bpDesktop) {
					width: 100%;
				}

				&--2 {
					@include mq-min($bpDesktop) {
						@include font-size($fontSize);
						@include line-height($fontHeight);
						width: 50%;
					}
				}
			}

			&__link {
				color: $colorGrey;
				display: block;
				@include font-size($fontSize - 1);
				@include line-height($fontHeight - 1);
				font-family: $fontBase;
				margin: 0;
				padding: 5px 10px;
				text-decoration: none;

				&.active, &:hover {
					color: $colorPrimary;

					@include mq-min($bpDesktop) {
						color: $colorWhite;
					}
				}

				&.focus-visible {
					@include focus-style-alt($colorWhite);
				}

				&.active {
					@include mq-min($bpDesktop) {
						color: $colorWhite;
						text-decoration: underline;
					}
				}

				@include mq-min($bpDesktop) {
					color: $colorWhite;
					color: rgba($colorWhite, .8);
					@include font-size($fontSize);
					@include line-height($fontHeight);
					@include text-overflow();
				}
			}

			@include mq-min($bpDesktop) {
				padding: 8px 0 15px;
			}

			&:first-child {
				&:before {
					display: none;
				}

				@include mq-min($bpDesktop) {
					padding-top: 0;
				}
			}
		}

		@include mq-min($bpDesktop) {
			background: $colorPrimary;
			border-top: 2px solid $colorWhite;
			@include box-shadow();
			left: 2px;
			margin: 0;
			padding: 15px 15px 25px 15px;
			position: absolute;
			top: 100%;
			width: 225px;
		}

		@include mq-min($bpDesktopLarge) {
			padding: 25px 15px 30px 15px;
			width: 250px;
		}

		@include mq-min($bpLarge) {
			padding: 35px 15px 35px 15px;
		}

		&--full {
			left: 0;
			width: 100%;
		}

		&--alt {
			@include mq-min($bpDesktop) {
				left: auto;
				right: 2px;
			}
		}
	}

	@include mq-min($bpDesktop) {
		background: $colorOffWhite;
	}
}
