﻿@import "../config";

.m-promo-block
{
	background: $colorBase;
	color: $colorWhite;
	margin: -5px auto;
	padding: 200px 0 0 0;
	position: relative;
	max-width: $maxPageWidth;
	z-index: 1;
	&__inner
	{
		margin: 0;
		padding: 0;
		@include mq-min($bpDesktop)
		{
			@include min-height(375px);
		}
	}
	&__image
	{
		background: $colorOffWhite no-repeat 50% 50%;
		@include background-size(cover);
		height: 200px;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
		@include mq-min($bpTabletLarge)
		{
			bottom: 0;
			height: auto;
			right: 50%;
		}
	}
	&__content
	{
		margin: 0;
		padding: 15px;
		@include mq-min($bpTablet)
		{
			padding: 30px;
		}
		@include mq-min($bpDesktop)
		{
			padding: 45px;
		}
	}
	&__title
	{
		@include font-size($fontSize + 9);
		@include line-height($fontHeight + 9);
		margin: 0;
		padding: 0 0 20px 0;
		&__link
		{
			color: $colorWhite;
			font-family: $fontBase;
			text-decoration: none;
			&:hover, &:focus
			{
				color: $colorWhite;
				text-decoration: underline;
			}
		}
	}
	&__summary
	{
		@include font-size($fontSize + 4);
		@include line-height($fontHeight + 4);
		margin: 0;
		padding: 0 0 15px 0;
	}
	&__action
	{
		margin: 0;
		padding: 15px 0 0 0;
		.form-button
		{
			color: $colorBase;
			&:hover, &:focus
			{
				color: $colorWhite !important;
			}
			.m-promo-block--orange &, .m-promo-block--cf5205 &
			{
				color: $colorPrimary;
			}
			.m-promo-block--teal &, .m-promo-block--227374 &
			{
				color: $colorSecondary;
			}
			.m-promo-block--pink &, .m-promo-block--b31f29 &
			{
				color: $colorTertiary;
			}
		}
	}
	&__faux-link
	{
		display: block;
		height: 200px;
		left: 0;
		position: absolute;
		right: 0;
		@include text-hidden();
		top: 0;
		z-index: 1;
		@include mq-min($bpTabletLarge)
		{
			bottom: 0;
			height: auto;
			right: 50%;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		margin: 0 auto;
		padding: 0 0 0 50%;
	}
	@include mq-min($bpDesktop)
	{
		margin: 5px auto;
	}
	&--orange, &--cf5205
	{
		background: $colorPrimary;
	}
	&--teal, &--227374
	{
		background: $colorSecondary;
	}
	&--pink, &--b31f29
	{
		background: $colorTertiary;
	}
}