@import "../config";

.m-help-link
{
	background: $colorPrimary;
	display: inline-block;
	height: 19px;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	@include text-hidden();
	vertical-align: middle;
	top: -1px;
	width: 19px;
	z-index: 1;
	&:before
	{
		color: $colorWhite;
		content: "?";
		display: block;
		font-family: $fontSimple;
		font-size: 12px;
		font-weight: bold;
		left: 0;
		line-height: 12px;
		margin: 0;
		padding: 0;
		position: absolute;
		text-align: center;
		text-indent: 0;
		right: 0;
		top: 4px;
		z-index: 1;
	}
	&:hover
	{
		background: $colorBase;
	}
}

.m-help-link-wrapper {
	display: inline-block;
	position: relative;
	margin: 0;
	padding: 0 29px 0 0;

	.m-help-link {
		position: absolute;
		top: 0;
		right: 0;
	}
}