﻿@import "../config";

.m-contact-details
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		font-size: 0;
		line-height: 0;
		margin: -15px 0 0 -15px;
		padding: 0 0 15px 0;
		@include mq-min($bpDesktop)
		{
			margin: -20px 0 0 -20px;
		}
		@include mq-min($bpDesktopLarge)
		{
			margin: -25px 0 0 -25px;
		}
	}
	&__group
	{
		@include box-sizing();
		display: inline-block;
		@include font-size($fontSize);
		@include line-height($fontHeight);
		margin: 0;
		padding: 15px 0 0 15px;
		vertical-align: top;
		width: 100%;
		&__title
		{
			font-family: $fontBold;
			@include font-size($fontSize + 4);
			font-weight: normal;
			@include line-height($fontHeight + 4);
			margin: 0;
			padding: 0 0 10px 0;
		}
		@include mq-min($bpMobileLarge)
		{
			width: 50%;
		}
		@include mq-min($bpTabletLarge)
		{
			width: 100%;
		}
		@include mq-min($bpDesktop)
		{
			padding: 20px 0 0 20px;
			width: 50%;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 25px 0 0 25px;
		}
	}
	&__address
	{
		margin: 0;
		padding: 0;
	}
	&__hours
	{
		margin: 0;
		padding: 0;
		&__title, &__data
		{
			@include box-sizing();
			float: left;
			margin: 0;
			padding: 5px 0 0 0;
			width: 65%;
		}
		&__title
		{
			clear: both;
			font-family: $fontBold;
			font-weight: normal;
			padding-right: 5px;
			width: 35%;
			max-width: 100px;
		}
	}
	&__item
	{
		color: $colorPrimary;
		font-family: $fontBold;
		@include font-size($fontSize + 1);
		font-weight: normal;
		@include line-height($fontHeight + 1);
		margin: 0;
		padding: 5px 0 0 0;
		a
		{
			color: $colorPrimary;
			text-decoration: none;
			&:hover
			{
				color: $colorBase;
				text-decoration: none;
			}
		}
		&:before
		{
			color: $colorBase;
			content: "";
			font-family: $fontBold;
			@include font-size($fontSize);
			font-weight: normal;
			@include line-height($fontHeight);
		}
		&--phone
		{
			&:before
			{
				content: "t. ";
			}
		}
		&--fax
		{
			&:before
			{
				content: "f. ";
			}
		}
		&--email
		{
			&:before
			{
				content: "e. ";
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 30px;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}