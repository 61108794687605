﻿@import "../config";

.m-more
{
	margin: 0;
	padding: 15px 0;
	&__link
	{
		border-bottom: 1px solid $colorLink;
		color: $colorLink;
		display: inline-block;
		font-family: $fontBold;
		margin: 0;
		padding: 5px 20px 5px 0;
		position: relative;
		z-index: 1;
		&:before, &:after
		{
			border: 5px solid transparent;
			border-bottom-width: 0;
			border-top-width: 5px;
			content: "";
			display: block;
			margin: 0;
			padding: 0;
			right: 3px;
			position: absolute;
		}
		&:before
		{
			border-top-color: $colorLink;
			top: 12px;
			z-index: 1;
		}
		&:after
		{
			border-top-color: $colorWhite;
			top: 10px;
			z-index: 2;
		}
		&:hover, &:focus
		{
			color: $colorBase;
			border-bottom-color: $colorBase;
			&:before
			{
				border-top-color: $colorBase;
			}
		}
	}
	&--close
	{
		padding-top: 0;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}