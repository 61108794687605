﻿.c-alert-banner {
    background-color: $colorPrimary;
    color: $colorWhite;
    padding: 10px;
    font-size: 16px;
    text-align: center;
}

.c-alert-banner__content {
    max-width: $maxPageWidth;
    margin: 0 auto;
    a {
        color: $colorWhite;
        text-decoration: underline;
    }
    p:last-child {
        padding-bottom: 0;
    }
}