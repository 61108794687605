﻿@import "../config";

.m-tabs
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__action
	{
		display: none;
		float: right;
		margin: 0;
		padding: 0 0 0 15px;
		@include mq-min($bpDesktop)
		{
			display: block;
		}
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: 0 0 15px 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		z-index: 1;
		&:before
		{
			border-bottom: 1px solid $colorDivider;
			bottom: 0;
			content: "";
			display: block;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			z-index: 1;
		}
		&__item
		{
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 0;
			position: relative;
			vertical-align: top;
			max-width: 33.3333%;
			z-index: 2;
		}
		&__link
		{
			@include box-sizing();
			border: 1px solid $colorWhite;
			border-bottom-color: $colorDivider;
			color: $colorLink;
			display: block;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 11px 10px;
			position: relative;
			text-align: center;
			text-decoration: none;
			z-index: 2;
			&:hover, &:focus
			{
				color: $colorBase;
				text-decoration: none;
			}
			&.active
			{
				background: $colorWhite;
				border: 1px solid $colorDivider;
				border-bottom-color: $colorWhite;
				color: $colorGrey;
			}
			&.focus-visible {
				@include focus-style-alt();
			}
			@include mq-min($bpDesktop)
			{
				@include font-size($fontSize);
				padding: 13px 20px;
			}
			@include mq-min($bpDesktopLarge)
			{
				@include font-size($fontSize + 1);
				padding: 13px 30px;
			}
		}
	}
	&__content
	{
		margin: 0;
		padding: 10px 10px 0 10px;
		position: relative;
		z-index: 1;
		@include mq-min($bpDesktop)
		{
			padding: 10px 20px 0 20px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 10px 30px 0 30px;
		}
	}
	&__tab
	{
		margin: 0;
		padding: 0;
		&__title
		{
			@include font-size($fontSize + 5);
			font-weight: normal;
			@include line-height($fontHeight + 5);
			margin: 0;
			padding: 0 0 15px 0;
			@include mq-min($bpTabletLarge)
			{
				padding-bottom: 20px;
			}
		}
		&__content
		{
			margin: 0;
			padding: 0;
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}