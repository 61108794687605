@import "config";
@import "base/forms";

// inputs
.form-text, .form-select
{
	height: 42px;
}

.form-select
{
	> option
	{
		&.unavailable
		{
			color: #bbb;
		}
	}
}

// buttons
.form-button {
	background: $colorSecondary;
	border-color: $colorSecondary;
	border-width: 2px;
	color: $colorWhite;
	@include font-size($fontSize + 1);
	font-weight: normal;
	padding: 12px 15px;
	text-transform: none;
	min-width: 90px;

	&:hover, &:focus {
		background: $colorSecondaryHover;
		border-color: $colorSecondaryHover;
		color: $colorWhite;
	}

	&--alt, &--base {
		background: $colorBase;
		border-color: $colorBase;
		color: $colorWhite;

		&:hover {
			background: $colorBaseHover;
			border-color: $colorBaseHover;
			color: $colorWhite;
		}
	}

	&--action {
		background: $colorPrimary;
		border-color: $colorPrimary;
		color: $colorWhite;

		&:hover {
			background: $colorPrimaryHover;
			border-color: $colorPrimaryHover;
			color: $colorWhite;
		}
	}

	&--tertiary {
		background: $colorTertiary;
		border-color: $colorTertiary;
		color: $colorWhite;

		&:hover {
			background: $colorTertiaryHover;
			border-color: $colorTertiaryHover;
			color: $colorWhite;
		}
	}

	&--large {
		@include font-size($fontSize + 2);
		padding: 12px 20px;

		@include mq-min($bpDesktop) {
			padding: 12px 25px;
			min-width: 120px;
		}
	}

	&--massive {
		@include font-size($fontSize + 2);
		padding: 10px 20px;

		@include mq-min($bpTabletLarge) {
			padding: 12px 25px;
		}

		@include mq-min($bpDesktop) {
			@include font-size($fontSize + 4);
			padding: 16px 35px;
		}
	}

	&--white {
		background: $colorWhite;
		border-color: $colorWhite;
		color: $colorPrimary;

		&:hover, &:focus {
			background: none;
			border-color: $colorWhite;
			color: $colorWhite;
		}
	}

	&--line {
		background: none;
		border-color: $colorSecondary;
		color: $colorSecondary;

		&:hover {
			background: $colorSecondary;
			border-color: $colorSecondary;
			color: $colorWhite;
		}
	}

	&--clear {
		background: none;
		border: 2px solid $colorWhite;
		color: $colorWhite;

		&:hover {
			background: $colorWhite;
			border-color: $colorWhite;
			color: $colorSecondary;
		}

		&--dark {
			border-color: $colorBase;
			color: $colorBase;
		}
	}

	&--text {
		background: none;
		border: 0 !important;
		color: $colorPrimary;
		padding: 12px 5px;
		text-transform: none;

		&:hover {
			background: none;
			border-color: transparent;
			color: $colorBase;
		}
	}

	&--flush {
		padding-left: 0;
		padding-right: 0;
	}

	&--search {
		background: $colorWhite;
		border-color: $colorWhite;
		height: 38px;
		padding: 0;
		position: relative;
		@include text-hidden();
		min-width: 38px;
		width: 20px;
		z-index: 1;

		&:before {
			@include bg('/images/camc/common/sprite.png', no-repeat, -72px 0);
			content: "";
			display: block;
			height: 23px;
			left: 50%;
			margin: -12px 0 0 -12px;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 24px;
			z-index: 1;
		}

		&:hover,
		&:focus {
			background: $colorWhite;
			border-color: $colorWhite;

			&:before {
				background-position: -24px 0;
			}
		}

		&--grey {
			&:before {
				background-position: -24px 0;
			}

			&:hover {
				&:before {
					background-position: 0 0;
				}
			}
		}

		&--black {
			&:before {
				background-position: 0 0;
			}

			&:hover {
				&:before {
					background-position: -24px 0;
				}
			}
		}

		&--white {
			background: none;
			border-color: transparent;

			&:before {
				background-position: -48px 0;
			}

			&:hover {
				background: none;
				border-color: transparent;
				@include opacity();

				&:before {
					background-position: -48px 0;
				}
			}
		}

		&--blue {
			&:before {
				background-position: -96px 0;
			}

			&:hover {
				&:before {
					background-position: 0 0;
				}
			}
		}
	}

	&--paypal-checkout {
		@include bg('/images/camc/common/sprite.png', no-repeat, 0 -236px);
		background-color: #ffc439;
		border: 1px solid #dc911d;
		height: 42px !important;
		padding: 0;
		@include text-hidden();
		width: 226px !important;

		&:hover,
		&:focus {
			@include bg('/images/camc/common/sprite.png', no-repeat, 0 -236px);
			@include box-shadow(0 0 0 3px rgba(#dc911d, .25));
			border-color: #dc911d;
		}
	}
}

// info
.form-info
{
	color: $colorGreyMid;
	@include font-size($fontSize - 2);
	@include line-height($fontHeight - 2);
	a
	{
		color: $colorGreyMid;
		font-family: $fontBase;
		font-weight: normal;
		text-decoration: underline;
		&:hover, &:focus
		{
			color: $colorGreyMid;
			text-decoration: none;
		}
	}
}

// messages
.form-message, .form-error, .form-success, .form-warning
{
	a
	{
		font-family: $fontBase;
		font-weight: normal;
	}
}

// jquery-ui: autocomplete
.ui-autocomplete
{
	background: $colorWhite;
	border: 1px solid $colorBorder;
	@include border-radius(0);
	@include box-shadow();
	color: $colorBase;
	font-family: $fontSimple;
	@include font-size($fontSize);
	max-height: 200px;
	left: 0;
	@include line-height($fontHeight);
	margin: 0;
	overflow-y: auto;
	padding: 0;
	text-align: left;
	top: 0;
	width: 240px;
	z-index: 9990;
	.ui-menu-item
	{
		border: 0;
		@include border-radius(0);
		margin: 0;
		padding: 5px 10px;
		&.ui-state-focus
		{
			background: $colorSecondaryLight;
			color: $colorWhite;
		}
	}
	@include mq-min($bpDesktop)
	{
		max-height: 250px;
		width: 270px;
	}
	@include mq-min($bpDesktopLarge)
	{
		width: 300px;
	}
	@include mq-min($bpLarge)
	{
		width: 330px;
	}
}

// jquery-ui: datepicker
.ui-datepicker 
{
	border: 0;
	@include border-radius(0);
	@include box-shadow(0 2px 2px rgba(0,0,0,.2));
	margin: 0;
	padding: 5px;
	width: 230px !important;
	z-index: 5555 !important;
	.ui-datepicker-header 
	{
		border: 0;
		height: 30px;
		margin: 0;
		padding: 0;
	}
	.ui-datepicker-title 
	{
		background: $colorDivider;
		color: $colorBase;
		font-family: $fontBold;
		@include font-size($fontSize - 1);
		font-weight: normal;
		height: 25px;
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 5px 30px 0 30px;
		text-align: center;
		text-transform: uppercase;
	}
	.ui-datepicker-next, 
	.ui-datepicker-prev
	{
		background: none;
		border: 0;
		@include border-radius(0);
		cursor: pointer;
		height: 30px;
		top: 0;
		width: 30px;
		&.ui-state-disabled 
		{
			visibility: hidden;
		}
		&.ui-state-hover 
		{
			background: lighten($colorDivider, 5%);
		}
	}
	.ui-datepicker-prev 
	{
		left: 0;
	}
	.ui-datepicker-next 
	{
		right: 0;
	}
	.ui-datepicker-calendar 
	{
		border: 0;
		border-collapse: collapse;
		margin: 0;
		padding: 0;
		table-layout: fixed;
		thead 
		{
			tr 
			{
				th, td 
				{
					background: $colorBase;
					color: $colorWhite;
					font-family: Verdana, sans-serif;
					@include font-size($fontSize - 3);
					font-weight: normal;
					height: 28px;
					@include line-height($fontHeight - 3);
					margin: 0;
					padding: 0;
					text-align: center;
					vertical-align: middle;
					width: 25px;
				}
			}
		}
		tbody 
		{
			tr 
			{
				th, td 
				{
					background: $colorWhite;
					border: 0;
					font-family: Verdana, sans-serif;
					@include font-size($fontSize - 3);
					font-weight: normal;
					height: 25px;
					@include line-height($fontHeight - 3);
					margin: 0;
					padding: 0;
					text-align: center;
					vertical-align: middle;
					width: 25px;
					span 
					{
						background: lighten($colorDivider, 5%);
						border-color: lighten($colorDivider, 5%);
						color: $colorBase;
						display: block;
						height: 20px;
						margin: 1px 0 0 1px;
						padding: 3px 2px 2px 5px;
						text-align: left;
					}
					a
					{
						display: block;
						height: 20px;
						margin: 1px 0 0 1px;
						padding: 3px 2px 2px 5px;
						text-align: left;
						&.ui-state-default 
						{
							background: lighten($colorDivider, 5%);
							border-color: lighten($colorDivider, 5%);
							color: $colorBase;
						}
						&.ui-state-highlight 
						{
							background: lighten($colorDivider, 5%);
							border-color: darken($colorDivider, 25%);
							color: $colorBase;
						}
						&:hover, &.ui-state-hover, &.ui-state-active 
						{
							background: $colorPrimary;
							border-color: $colorPrimary;
							color: $colorWhite;
						}
					}
					&:first-child 
					{
						a 
						{
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}
