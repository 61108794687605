﻿@import "../config";

.m-category-product {
	background: $colorWhite;
	border: 1px solid $colorOffWhite;
	@include box-shadow(1px 1px 2px rgba($colorBlack, .05));
	margin: 0;
	padding: 0;
	position: relative;
	@include transition(box-shadow .2s);
	z-index: 1;

	.m-grid__row--flex & {

		@supports (display: flex) {
			width: 100%;

			@include mq-min($bpTablet) {
				min-height: 100%;
				display: flex;

				.m-category-product__inner {
					display: flex;
					flex-flow: column nowrap;
					height: 100%;
					align-items: flex-start;
					width: 100%;
				}

				.m-category-product__actions {
					margin: auto 0 0 0;
					width: 100%;
				}

				.m-category-product__content,
				.m-category-product__cost,
				.m-category-product__delivery {
					width: 100%;
				}

				.m-category-product__image-wrapper {
					width: 100%;
					position: relative;
				}

				.m-category-product__options {
					z-index: 10;

					@include mq-min($bpTablet) {
						right: -10px;
					}

					@include mq-min($bpDesktop) {
						right: -15px;
					}
				}
			}
		}
	}

	&__inner {
		@include min-height(75px);
		margin: 0;
		padding: 10px 10px 10px 120px;

		@include mq-min($bpTablet) {
			padding-left: 10px;
		}

		@include mq-min($bpDesktop) {
			padding: 15px;
		}
	}

	&__image {
		left: 0;
		margin: 0;
		padding: 10px;
		position: absolute;
		top: 0;
		width: 100px;
		z-index: 1;

		&__link {
			display: block;
			margin: 0;
			padding: 0;

			&:hover, &:focus {
				.m-category-product__image__image {
					@include opacity();
				}
			}
		}

		&__image {
			display: block;
			margin: 0;
			padding: 0;
		}

		&__options {
			background: $colorSecondary;
			color: $colorWhite;
			bottom: 0;
			font-family: $fontBold;
			@include font-size($fontSize - 2);
			font-weight: normal;
			@include line-height($fontHeight - 2);
			margin: 0;
			padding: 5px 10px;
			position: absolute;
			text-transform: uppercase;
			right: 0;
			z-index: 2;
		}

		@include mq-min($bpTablet) {
			left: auto;
			margin: -10px -10px 0 -10px;
			padding: 0;
			position: relative;
			top: auto;
			width: auto;
		}

		@include mq-min($bpDesktop) {
			margin: -15px -15px 0 -15px;
		}
	}

	&__content {
		@include min-height(40px);
		margin: 0;
		padding: 0;

		@include mq-min($bpTablet) {
			@include min-height(60px);
			padding-top: 10px;
		}

		@include mq-min($bpTabletLarge) {
			@include min-height(75px);
		}

		@include mq-min($bpDesktop) {
			@include min-height(60px);
			padding-top: 15px;
		}
	}

	&__title {
		display: -webkit-box;
		@include font-size($fontSize);
		font-family: $fontBase;
		@include line-height($fontHeight - 1);
		margin: 0;
		overflow: hidden;
		padding: 0;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		&__link {
			color: $colorBase;
			display: block;
			font-family: $fontBase;
			margin: 0;
			padding: 0;

			&:hover, &:focus {
				color: $colorPrimary;
			}

			&.focus-visible {
				@include focus-style-alt();
			}
		}

		@include mq-min($bpTablet) {
			@include font-size($fontSize + 1);
			@include line-height($fontHeight);
		}

		.m-grid__col--2--desktop & {
			@include font-size($fontSize);
			@include line-height($fontHeight - 1);
		}
	}

	&__cost {
		margin: 0;
		padding: 15px 0 0 0;
		// Make the products equal height if there is no delivery message
		&--no-delivery-message {
			margin-bottom: 24px;

			@include mq-min($bpDesktop) {
				margin-bottom: 25px;
			}
		}
	}

	&__price {
		@include box-sizing();
		color: $colorSecondary;
		float: left;
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0 15px 0 0;
		max-width: 50%;

		&__title {
			display: block;
			font-family: $fontBold;
			@include font-size($fontSize - 2);
			@include line-height($fontHeight - 4);
			margin: 0 0 -3px 0;
			padding: 0;

			@include mq-min($bpDesktop) {
				@include font-size($fontSize);
				@include line-height($fontHeight - 2);
			}

			.m-grid__col--2--desktop & {
				@include font-size($fontSize - 2);
				@include line-height($fontHeight - 4);
			}
		}

		&__list {
			color: $colorSecondary;
			display: block;
			font-family: $fontHeavy;
			@include font-size($fontSize + 3);
			@include line-height($fontHeight + 1);
			margin: 0;
			padding: 0;

			&__from {
				@include font-size($fontSize - 2);
				@include line-height($fontHeight - 2);
			}

			@include mq-min($bpDesktop) {
				@include font-size($fontSize + 10);
				@include line-height($fontHeight + 8);
			}

			.alt & {
				color: $colorPrimary;
			}

			.m-grid__col--2--desktop & {
				@include font-size($fontSize + 3);
				@include line-height($fontHeight + 1);
			}
		}

		&.alt {
			color: $colorPrimary;
		}

		.m-grid__col--2--desktop & {
			padding-right: 10px;
		}
	}

	&__delivery {
		padding: 5px 0 0 0;

		.m-banded-block & {
			min-height: 34px; // two lines to keep the grid height in check
			@include mq-max($bpTablet - 1) {
				padding-bottom: 15px;
				min-height: 0;
			}
		}
	}

	&__actions {
		display: none;
		margin: 0;
		padding: 15px 0 0 0;

		@include mq-min($bpTablet) {
			display: block;
		}
	}

	&__faux-link {
		bottom: 0;
		display: block;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		@include text-hidden();
		top: 0;
		z-index: 100;

		@include mq-min($bpTablet) {
			display: none;
		}
	}
}