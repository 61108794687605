@import "../config";

.l-header {
	background: $colorOffWhite;
	border-bottom: 2px solid $colorWhite;
	margin: 0 -10px;
	padding: 0 10px;

	&__inner {
		@include box-sizing();
		font-size: 0;
		line-height: 0;
		margin: 0 auto;
		padding: 10px 0 0 0;
		position: relative;
		text-align: center;
		max-width: $maxPageWidth;
		z-index: 8888;

		@include mq-min($bpDesktop) {
			padding: 40px 0 25px 0;
			text-align: left;
		}
	}

	&__logo {
		display: block;
		float: left;
		margin: 0 0 10px 0;
		padding: 0;
		position: relative;
		vertical-align: middle;
		z-index: 2;

		&__link {
			display: block;
			margin: 0;
			padding: 0;
		}

		&__image {
			height: 28px;
			padding: 3px 0;

			@include mq-min($bpMobileLarge - 140) {
				height: 34px;
				padding: 0;
			}

			@include mq-min($bpTablet) {
				height: 44px;
			}

			@include mq-min($bpDesktop) {
				height: 58px;
			}
		}

		@include mq-min($bpDesktop) {
			margin: 0;
		}
	}

	&__shop {
		//background: yellow;
		color: $colorPrimary;
		display: none;
		@include font-size($fontSize + 13);
		left: 286px;
		@include line-height($fontHeight + 13);
		margin: 0;
		padding: 0;
		position: absolute;
		text-align: center;
		right: 128px;
		top: 13px;
		z-index: 1;

		@include mq-min($bpTablet) {
			display: block;
		}

		@include mq-min($bpDesktop) {
			font-family: $fontLight;
			@include font-size($fontSize + 33);
			left: 366px;
			@include line-height($fontHeight + 33);
			right: 298px;
			top: 40px;
		}
	}

	&__prima {
		background: $colorWhite;
		clear: both;
		font-size: 0;
		line-height: 0;
		margin: 0 -10px;
		padding: 5px 10px;
		position: relative;
		z-index: 2;

		&__inner {
			color: $colorGrey;
			display: inline-flex;
			align-items: center;
			white-space: nowrap;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0 auto;
			padding: 0;
			position: relative;
			z-index: 1;

			@include mq-min($bpDesktop) {
				padding: 10px 0;
			}
		}

		&__logo {
			height: 30px;
			width: 94px;
			margin-left: 8px;

			@include mq-min($bpDesktop) {
				height: 42px;
				width: 130px;
			}
		}

		@include mq-min($bpDesktop) {
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 57px;
			z-index: 2;
		}
	}

	&__visit {
		display: none;
		@include font-size($fontSize);
		@include line-height($fontHeight);
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		top: 15px;
		z-index: 3;

		&__link {
			color: $colorPrimary;
			text-decoration: underline;

			&:hover, :focus {
				color: $colorPrimary;
				text-decoration: none;
			}
		}

		@include mq-min($bpDesktop) {
			display: block;
		}
	}

	&__basket {
		border-left: 1px solid $colorDivider;
		height: 54px;
		margin: 0;
		padding: 0;
		position: absolute;
		right: -10px;
		top: 0;
		width: 54px;
		z-index: 2;

		&__link {
			//background: yellow;
			display: block;
			height: 100%;
			margin: 0;
			padding: 0;

			&:before {
				@include bg("/images/camc/common/sprite.png", no-repeat, -35px -33px);
				content: "";
				display: block;
				height: 20px;
				left: 15px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 14px;
				width: 25px;
				z-index: 1;

				@include mq-min($bpTablet) {
					left: 20px;
					top: 20px;
				}
			}

			&__count {
				background: #f88c29;
				@include border-radius(100%);
				@include box-sizing();
				color: $colorWhite;
				display: block;
				font-family: $fontSimple;
				font-size: 9px;
				font-weight: bold;
				height: 15px;
				line-height: 9px;
				left: 11px;
				margin: 0;
				overflow: hidden;
				padding: 4px 0 0 0;
				position: absolute;
				text-align: center;
				top: 26px;
				width: 15px;
				z-index: 2;

				@include mq-min($bpTablet) {
					left: 16px;
					top: 32px;
				}
			}
		}

		@include mq-min($bpTablet) {
			height: 64px;
			width: 64px;
		}

		@include mq-min($bpDesktop) {
			display: none;
		}

		.js & {
			right: 45px;

			@include mq-min($bpTablet) {
				right: 55px;
			}
		}
	}

	&__skip {
		font-size: 0;
		left: -15px;
		line-height: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 0;
		max-width: 100%;
		z-index: 3;

		&__item {
			display: block;
			@include font-size($fontSize);
			@include line-height($fontHeight);
			margin: 0;
			padding: 0;
		}

		&__link {
			background: $colorWhite;
			color: $colorBase;
			display: block;
			margin: 0;
			padding: 5px;
			text-decoration: none;
			position: fixed;
			top: -200px;
			width: auto;
			z-index: 1;

			&:hover, &:focus {
				color: $colorBase;
			}

			&:focus {
				left: 0;
				top: 0;
				z-index: 100;
			}
		}
	}

	&__help {
		display: none;
		font-family: $fontBold;
		@include font-size($fontSize - 1);
		font-weight: normal;
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		text-align: right;
		top: 10px;
		max-width: 190px;
		z-index: 1;

		&__phone {
			color: $colorPrimary;
			display: block;
			font-family: $fontBold;
			@include font-size($fontSize + 2);
			@include line-height($fontHeight);
			margin: 0;
			padding: 0;

			@include mq-min($bpDesktop) {
				@include font-size($fontSize + 8);
				@include line-height($fontHeight + 8);
			}
		}

		@include mq-min($bpTabletLarge) {
			display: block;
		}

		@include mq-min($bpDesktop) {
			@include font-size($fontSize + 1);
			@include line-height($fontHeight + 1);
			top: 35px;
			max-width: 250px;
		}
	}

	&__secure {
		background: $colorOffWhite;
		font-size: 0;
		@include min-height(24px);
		line-height: 0;
		margin: 0 -10px;
		padding: 15px 10px;
		text-align: center;

		&__inner {
			color: $colorGreyDark;
			display: inline-block;
			@include font-size($fontSize - 1);
			@include min-height(17px);
			@include line-height($fontHeight - 1);
			margin: 0 auto;
			padding: 2px 0 0 25px;
			position: relative;
			text-transform: uppercase;
			max-width: $maxPageWidth;
			z-index: 1;

			&:before {
				@include bg('/images/camc/common/sprite.png', no-repeat, -210px -130px);
				content: "";
				display: block;
				height: 19px;
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 2px;
				width: 14px;
				z-index: 1;
			}

			@include mq-min($bpMobileLarge) {
				padding-right: 25px;
			}
		}
	}

	@include mq-min($bpDesktop) {
		background: $colorWhite;
	}
}
