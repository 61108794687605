@import "../config";

.m-share {
	margin: 0;
	padding: 0 0 15px 0;

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__title {
		color: $colorGreyMid;
		font-family: $fontBold;
		@include font-size($fontSize - 1);
		font-weight: normal;
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 0 0 10px 0;
		text-transform: uppercase;
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: -2px 0 0 -2px;
		padding: 0;

		&__item {
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 2px 0 0 2px;
			vertical-align: top;

			@include mq-min($bpDesktop) {
				padding: 5px 0 0 5px;
			}
		}

		&__link {
			background: $colorWhite;
			border: 2px solid $colorPrimary;
			@include box-sizing();
			display: block;
			height: 38px;
			margin: 0;
			padding: 0;
			position: relative;
			width: 38px;
			z-index: 1;

			&:before {
				@include bg('/images/camc/common/sprite.png', no-repeat, 0 -127px);
				content: "";
				display: block;
				height: 22px;
				left: 50%;
				margin: -12px 0 0 0;
				padding: 0;
				position: absolute;
				top: 50%;
				z-index: 1;
			}

			&:hover {
				background: $colorPrimary;

				&:before {
					background-position-y: -95px;
				}
			}

			@include mq-min($bpDesktop) {
				height: 48px;
				width: 48px;
			}

			&--facebook {
				&:before {
					background-position-x: 0;
					margin-left: -5px;
					width: 10px;
				}
			}

			&--twitter {
				&:before {
					background-position-x: -20px;
					margin-left: -11px;
					width: 23px;
				}
			}

			&--instagram {
				&:before {
					background-position-x: -53px;
					margin-left: -12px;
					width: 22px;
				}
			}

			&--you-tube {
				&:before {
					background-position-x: -84px;
					margin-left: -12px;
					width: 23px;
				}
			}

			&--pinterest {
				&:before {
					background-position-x: -117px;
					margin-left: -9px;
					width: 17px;
				}
			}

			&--print {
				&:before {
					background-position-x: -176px;
					margin-left: -12px;
					width: 24px;
				}
			}

			&--clear {
				background: none;
				border-color: $colorWhite;

				&:before {
					background-position-y: -95px;
				}

				&:hover {
					background: $colorWhite;

					&:before {
						background-position-y: -64px;
					}
				}
			}

			&--no-border {
				border: 0;
			}
		}

		@include mq-min($bpDesktop) {
			margin: -5px 0 0 -5px;
		}
	}

	&--tight {
		padding-bottom: 0;
	}
}
