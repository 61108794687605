@import "../config";

.l-newsletter {
  background: $colorSecondary;
  color: $colorWhite;
  margin: 0 -10px;
  padding: 15px;
  text-align: center;

  &__inner {
    @include box-sizing();
    font-size: 0;
    line-height: 0;
    margin: 0 auto;
    padding: 0;
    text-align: left;
    max-width: $maxPageWidth;
  }

  &__title {
    @include font-size($fontSize + 6);
    @include line-height($fontHeight + 6);
    margin: 0;
    padding: 0 0 10px 0;

    @include mq-min($bpTabletLarge) {
      @include font-size($fontSize + 8);
      @include line-height($fontHeight + 8);
    }

    @include mq-min($bpDesktop) {
      @include font-size($fontSize + 10);
      @include line-height($fontHeight + 10);
    }
  }

  &__summary {
    margin: 0;
    padding: 0;

    @include mq-min($bpDesktop) {
      @include min-height(55px);
      max-width: 350px;
    }
  }

  &__form {
    margin: 0;
    padding: 0 0 15px 0;

    .form-row {
      margin: 0;
      padding: 0;

      .form-text {
        float: left;
        margin-right: 10px;
        width: 65%;
        width: calc(100% - 110px);
        max-width: none;

        @include mq-min($bpDesktop) {
          height: 48px;
        }
      }

      .form-button {
        float: right;
        padding-left: 5px;
        padding-right: 5px;
        min-width: 100px;
        width: 35%;
        max-width: 100px;
        margin-right: 0;

        @include mq-min($bpDesktop) {
          height: 48px;
        }
      }

      @include mq-min($bpTablet) {
        max-width: 450px;
      }
    }

    @include mq-min($bpDesktop) {
      padding-bottom: 0;
    }
  }

  @include mq-min($bpTabletLarge) {
    border-top: 0;
    padding: 25px 15px;
  }

  @include mq-min($bpDesktop) {
    padding: 35px 15px;
  }

  @include mq-min($bpDesktopLarge) {
    padding: 45px 15px;
  }
}
