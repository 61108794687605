﻿@import "../config";

.l-sitemap
{
	border-top: 5px solid $colorSecondary;
	margin: 0 -10px;
	padding: 15px;
	&__inner
	{
		@include box-sizing();
		font-size: 0;
		line-height: 0;
		margin: 0 auto;
		padding: 0;
		text-align: left;
		max-width: $maxPageWidth;
	}
	&__group
	{
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 0 0 15px 0;
		vertical-align: top;
		width: 100%;
		&__title
		{
			font-family: $fontBold;
			@include font-size($fontSize + 1);
			@include line-height($fontHeight + 1);
			margin: 0;
			padding: 0 0 10px 0;
		}
		&__inner
		{
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 0;
		}
		&__menu
		{
			font-size: 0;
			line-height: 0;
			margin: -3px 0 0 -5px;
			padding: 0;
			&__item
			{
				@include box-sizing();
				display: inline-block;
				@include font-size($fontSize - 1);
				@include line-height($fontHeight - 1);
				margin: 0;
				padding: 3px 0 0 5px;
				vertical-align: top;
				width: 50%;
				@include mq-min($bpTablet)
				{
					display: block;
					width: auto;
				}
			}
			&__link
			{
				color: $colorBase;
				display: block;
				font-family: $fontBold;
				margin: 0;
				padding: 5px 0;
				text-decoration: none;
				&:hover, &:focus
				{
					color: $colorPrimary;
					text-decoration: none;
				}
			}
		}
	}
	&__contact
	{
		color: $colorPrimary;
		font-family: $fontBold;
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 10px 0 0 0;
		&__title
		{
			color: $colorBase;
			display: inline-block;
			width: 15px;
			&:after
			{
				content: ".";
			}
		}
		&__item
		{
			margin: 0;
			padding: 3px 0 0 0;
			&:first-child
			{
				padding-top: 0;
			}
		}
		&__link
		{
			color: $colorPrimary;
			text-decoration: none;
			&:hover, &:focus
			{
				color: $colorBase;
			}
		}
		@include mq-min($bpDesktop)
		{
			padding-top: 20px;
		}
		&:first-child
		{
			padding-top: 0;
		}
	}
	&__address
	{
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		&__item
		{
			display: block;
			font-family: $fontBold;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 0;
			&:last-child
			{
				&:after
				{
					display: none;
				}
			}
		}
	}
	&__map
	{
		@include box-sizing();
		display: inline-block;
		margin: 0;
		padding: 0 0 20px 0;
		vertical-align: top;
		width: 100%;
		&__image
		{
			margin: 0;
			padding: 0;
		}
		@include mq-min($bpTablet)
		{
			padding: 15px 0 0 15px;
			width: 50%;
		}
		@include mq-min($bpDesktop)
		{
			display: block;
			padding: 0 0 20px 0;
			width: auto;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 25px 15px;
	}
	@include mq-min($bpDesktop)
	{
		padding: 35px 15px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 45px 15px;
	}
}