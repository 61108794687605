﻿@import "../config";

.m-orders
{
	@include box-sizing();
	margin: 0;
	padding: 0 0 15px 0;
	max-width: $maxContentWidth;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		@include font-size($fontSize + 3);
		font-weight: normal;
		@include line-height($fontHeight + 3);
		margin: 0;
		padding: 0 0 10px 0;
	}
	&__table
	{
		margin: 0;
		padding: 0 0 15px 0;
		table
		{
			margin: 0;
			tr
			{
				th, td
				{
					&.number
					{
						width: 80px;
					}
					&.date
					{
						width: 90px;
					}
					&.items
					{
						text-align: center;
						width: 70px;
						@include mq-max($bpDesktop - 1)
						{
							display: none;
						}
					}
					&.total
					{
						width: 80px;
						@include mq-max($bpMobileLarge - 1)
						{
							display: none;
						}
						@include mq-min($bpDesktop)
						{
							width: 100px;
						}
					}
					&.vin
					{
						width: 80px;
						@include mq-max($bpTablet - 1)
						{
							display: none;
						}
					}
					&.ref
					{
						@include mq-max($bpDesktop - 1)
						{
							display: none;
						}
					}
					&.status
					{
						@include mq-min($bpDesktop)
						{
							width: 120px;
						}
					}
				}
			}
		}
		&--tight
		{
			padding-bottom: 0;
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}