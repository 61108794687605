﻿@import "../config";

.l-back-to-top
{
	border-bottom: 5px solid $colorPrimary;
	height: 80px;
	margin: 0 -10px;
	overflow: hidden;
	padding: 0;
	position: relative;
	z-index: 1;
	&__link
	{
		background: $colorPrimary;
		border-top: 10px solid $colorWhite;
		bottom: -10px;
		height: 50px;
		left: 50%;
		margin: 0 0 0 -25px;
		padding: 0;
		position: absolute;
		@include text-hidden();
		@include transition(bottom .2s);
		width: 50px;
		z-index: 1;
		&:before
		{
			border: 8px solid transparent;
			border-bottom-color: $colorWhite;
			border-bottom-width: 12px;
			border-top-width: 0;
			content: "";
			display: block;
			height: 0;
			left: 50%;
			margin: -11px 0 0 -8px;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 0;
			z-index: 1;
		}
		&:hover
		{
			bottom: 0;
		}
	}
	@include mq-min($bpDesktop)
	{
		display: none;
	}
}