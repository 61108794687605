﻿@import "../config";

.m-header
{
	border-bottom: 1px solid $colorDivider;
	margin: 0 0 15px 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__image
	{
		display: block;
		float: left;
		margin: 0;
		padding: 0 15px 0 0;
		width: 20%;
		max-width: 240px;
		@include mq-min($bpTabletLarge)
		{
			padding-right: 20px;
		}
		@include mq-min($bpDesktop)
		{
			padding-right: 25px;
		}
		&--small
		{
			max-width: 100px;
			height: auto;
			padding-right: 20px;
			float: none;
			display: inline-block;
			vertical-align: middle;
		}
	}
	&__content
	{
		margin: 0;
		overflow: hidden;
		padding: 0;
	}
	&__title-block
	{
		width: 100%;
		padding: 0;
		margin: 0;
	}
	&__title
	{
		font-family: $fontBase;
		@include font-size($fontSize + 14);
		@include line-height($fontHeight + 14);
		margin: 0;
		padding: 0;
		&--inline
		{
			display: inline-block;
			vertical-align: middle;
		}
	}
	&__summary
	{
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 10px 0 0 0;
		&--alt
		{
			margin: 0;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		margin-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		margin-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		margin-bottom: 30px;
	}
	&--no-border
	{
		border-bottom: 0;
		padding-bottom: 0;
	}
	&--tight
	{
		margin-bottom: 0;
	}
}