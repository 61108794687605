﻿@import "../config";

.m-back
{
	margin: 0;
	padding: 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__content
	{
		@include box-sizing();
		margin: 0;
		padding: 0;
		@include mq-min($bpTabletLarge)
		{
			float: left;
			width: 70%;
			width: calc(100% - 200px);
		}
	}
	&__actions
	{
		@include box-sizing();
		display: none;
		float: right;
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 0 0 20px 10px;
		text-align: right;
		width: 30%;
		width: calc(200px);
		a
		{
			font-family: $fontBold;
		}
		@include mq-min($bpTabletLarge)
		{
			display: block;
		}
		@include mq-min($bpDesktop)
		{
			padding-bottom: 25px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-bottom: 30px;
		}
		@include mq-min($bpLarge)
		{
			padding-bottom: 32px;
		}
	}
}