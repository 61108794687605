﻿@import "../config";

.m-filter-block
{
	background: $colorOffWhite;
	margin: 0 0 15px 0;
	padding: 15px;
	&__title
	{
		cursor: pointer;
		font-family: $fontBold;
		@include font-size($fontSize);
		font-weight: normal;
		@include line-height($fontHeight);
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
		&:hover
		{
			text-decoration: underline;
		}
	}
	&__inner
	{
		margin: 0;
		padding: 0;
		.js &
		{
			display: none;
		}
	}
	&__content
	{
		margin: 0;
		padding: 20px 0 0 0;
	}
	&--tight
	{
		margin-bottom: 0;
	}
}