﻿.c-quantity {
	display: none;
}

.c-quantity.is-active {
	display: flex;
}

.c-quantity__button {
	background-color: $cQuantityButtonBackgroundColor;
	border: none;
	color: $colorWhite;
	font-weight: 600;
	font-family: inherit;
	font-size: 14px;
	padding: 11px 15px;
	cursor: pointer;
	transition: background-color 300ms;
}

.c-quantity__button:hover,
.c-quantity__button:focus {
	background-color: $cQuantityButtonBackgroundHoverColor;
	color: $colorWhite;
}

.c-quantity__initial.is-hidden {
	display: none;
}

.c-quantity__input {
	font-family: $fontBase;
	width: 45px;
	text-align: center;
	padding: 0;
	line-height: normal;
	border-radius: 0;
	border: none;
	border-top: 1px solid $cQuantityInputBorderColor;
	border-bottom: 1px solid $cQuantityInputBorderColor;
	-moz-appearance: textfield;
}

.c-quantity__input::-webkit-outer-spin-button,
.c-quantity__input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
