﻿@import "../config";

.m-checkout-summary
{
	background: $colorSecondary;
	color: $colorWhite;
	margin: 0 auto 15px auto;
	padding: 15px;
	&__inner
	{
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
	}
	&__title
	{
		@include font-size($fontSize + 6);
		@include line-height($fontHeight + 6);
		margin: 0;
		padding: 0 50px 15px 0;
		position: relative;
		z-index: 1;
		@include mq-min($bpTabletLarge)
		{
			padding-bottom: 20px;
		}
	}
	&__change
	{
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		top: 1px;
		z-index: 2;
		&__link
		{
			border: 1px solid $colorWhite;
			border-color: rgba($colorWhite, .8);
			color: $colorWhite;
			color: rgba($colorWhite, .8);
			font-family: $fontBase;
			@include font-size($fontSize - 2);
			@include line-height($fontHeight - 2);
			margin: 0;
			padding: 1px 8px;
			text-decoration: none;
			&:hover
			{
				border-color: $colorWhite;
				color: $colorWhite;
				text-decoration: none;
			}
		}
	}
	&__table
	{
		margin: 0;
		padding: 0;
		table
		{
			margin: 0;
			padding: 0;
			tr
			{
				th, td
				{
					border: 0;
					margin: 0;
					padding: 0 5px 15px 5px;
					small
					{
						color: $colorWhite;
					}
				}
				th
				{
					color: rgba($colorWhite, .8);
					font-weight: normal;
					width: 115px;
					&:after
					{
						content: ":";
					}
				}
			}
			tfoot
			{
				tr
				{
					th, td
					{
						border-top: 1px solid $colorWhite;
						border-top-color: rgba($colorWhite, .4);
						color: $colorWhite;
						font-family: $fontBold;
						@include font-size($fontSize + 4);
						@include line-height($fontHeight + 4);
						padding: 15px 5px 0 5px;
					}
				}
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		margin-bottom: 20px;
		padding: 20px;
	}
	@include mq-min($bpDesktop)
	{
		margin-bottom: 25px;
		padding: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		margin-bottom: 30px;
		padding: 30px;
	}
	&--tight
	{
		margin-bottom: 0;
	}
}