﻿@import "../config";

.m-category
{
	margin: 0;
	padding: 0;
	&__landing
	{
		margin: 0;
		padding: 0;
		&__menu
		{
			font-size: 0;
			line-height: 0;
			margin: 0 0 0 -15px;
			padding: 5px 0 40px 0;
			text-align: center;
			&__item
			{
				@include box-sizing();
				display: inline-block;
				@include font-size($fontSize);
				@include line-height($fontHeight);
				padding: 15px 0 0 15px;
				text-align: left;
				vertical-align: top;
				width: 50%;
				&__link
				{
					display: block;
					color: $colorPrimary;
					&:hover
					{
						color: $colorPrimary;
						.m-category__landing__menu__item__image-block
						{
							border-color: $colorPrimary;
						}
						.m-category__landing__menu__item__image
						{
							@include filter(grayscale(0));
						}
						.m-category__landing__menu__item__title,
						.m-category__landing__menu__item__title span
						{
							color: $colorPrimary;
						}
					}
				}
				&__image-block
				{
					border: 1px solid $colorBorder;
					display: block;
					padding: 20px 10px;
					@include mq-min($bpTablet)
					{
						padding: 30px 10px;
					}
				}
				&__image
				{
					display: block;
					margin: 0 auto;
					-webkit-filter: grayscale(1);
					filter: grayscale(1);
				}
				&__title
				{
					font-family: $fontBold;
					@include font-size($fontSize);
					font-weight: normal;
					@include line-height($fontHeight);
					padding: 10px 0;
					span
					{
						color: $colorGreyMid;
					}
				}
				@include mq-min($bpTabletLarge)
				{
					width: 25%;
					max-width: 322px;
				}
			}
			@include mq-min($bpTabletLarge)
			{
				padding: 10px 0 70px;
				text-align: left;
			}
		}
	}
}