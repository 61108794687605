﻿@import "../config";

.m-checkout-group
{
	margin: 0;
	padding: 0 0 15px 0;
	&__title
	{
		font-family: $fontBold;
		@include font-size($fontSize);
		font-weight: normal;
		@include line-height($fontHeight);
		margin: 0;
		padding: 0;
		&:after
		{
			content: ":";
		}
	}
	&__inner
	{
		@include font-size($fontSize - 2);
		@include line-height($fontHeight - 2);
		margin: 0;
		padding: 0;
	}
	&__address
	{
		margin: 0;
		padding: 10px 0 0 0;
	}
	&__message
	{
		font-style: italic;
		margin: 0;
		padding: 10px 0 0 0;
	}
	&__card
	{
		margin: 0;
		padding: 10px 0 0 0;
		&__title, &__value
		{
			margin: 0;
			padding: 0;
		}
		&__title
		{
			font-family: $fontBold;
			font-weight: normal;
		}
	}
	&__edit
	{
		@include font-size($fontSize - 3);
		@include line-height($fontHeight - 3);
		margin: 0;
		padding: 10px 0 0 0;
		&__link
		{
			font-family: $fontBold;
			font-weight: normal;
			text-decoration: none;
			text-transform: uppercase;
			&:hover
			{
				text-decoration: none;
			}
			&--delete
			{
				color: $colorRed;
				&:hover
				{
					color: $colorBase;
				}
			}
		}
	}
}