﻿@import "../config";

.m-checkout-terms
{
	margin: 0;
	padding: 15px 0 0 0;
	text-align: center;
	&__inner
	{
		border-top: 1px solid $colorDivider;
		margin: 0;
		padding: 15px 0 0 0;
		position: relative;
		z-index: 1;
		@include mq-min($bpTabletLarge)
		{
			padding-top: 20px;
		}
		@include mq-min($bpDesktop)
		{
			padding-top: 25px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-top: 30px;
		}
	}
	&__form
	{
		margin: 0 auto;
		padding: 0;
		max-width: 450px;
		&__inner
		{
			font-size: 0;
			line-height: 0;
			margin: -10px 0 0 -5px;
			padding: 0;
			.form-row, .form-action
			{
				@include box-sizing();
				display: inline-block;
				@include font-size($fontSize);
				@include line-height($fontHeight);
				margin: 0;
				padding: 10px 0 0 5px;
				text-align: left;
				vertical-align: top;
				width: 100%;
			}
			.form-row
			{
				@include mq-min($bpMobileLarge)
				{
					width: 400px;
				}
			}
			.form-action
			{
				.form-button
				{
					margin: 0;
				}
				@include mq-min($bpMobileLarge)
				{
					width: auto;
					max-width: 200px;
				}
			}
		}
		&__password
		{
			.form-row
			{
				label
				{
					@include mq-min($bpMobileLarge)
					{
						display: none;
					}
				}
			}
		}
		&__submit
		{
			margin: 0;
			padding: 15px 0 0 5px;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-top: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-top: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-top: 30px;
	}
	&--tight
	{
		padding-top: 0;
	}
}