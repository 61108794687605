﻿@import "../config";

.m-bundle-preview
{
	margin: 0;
	padding: 0;
	&__inner
	{
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
	}
	&__image
	{
		background: $colorOffWhite;
		border: 1px solid $colorDivider;
		float: left;
		font-size: 0;
		height: 105px;
		line-height: 0;
		margin: 0 15px 0 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		width: 140px;
		z-index: 1;
		&:before
		{
			background: $colorTertiary;
			bottom: 0;
			color: $colorWhite;
			content: attr(data-qty);
			display: block;
			font-family: $fontBold;
			@include font-size($fontSize - 2);
			font-weight: normal;
			@include line-height($fontHeight - 4);
			margin: 0;
			padding: 3px 9px;
			position: absolute;
			right: 0;
			z-index: 2;
		}
		&__image
		{
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;
		}
	}
	&__content
	{
		margin: 0;
		overflow: hidden;
		padding: 0;
	}
	&__title
	{
		@include font-size($fontSize + 1);
		@include line-height($fontHeight + 1);
		margin: 0;
		padding: 0;
	}
	&__rating
	{
		margin: 0;
		padding: 5px 0 0 0;
	}
	&__cost
	{
		margin: 0;
		padding: 10px 0 0 0;
	}
	&__price
	{
		color: $colorSecondary;
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		&__title
		{
			display: inline-block;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 4);
			margin: 0;
			padding: 0;
			vertical-align: middle;
			width: 100px;
		}
		&__list
		{
			font-family: $fontHeavy;
			@include font-size($fontSize + 3);
			@include line-height($fontHeight + 1);
			margin: 0;
			padding: 0;
			vertical-align: middle;
		}
		&.alt
		{
			color: $colorPrimary;
		}
	}
	&__faux-link
	{
		bottom: 0;
		display: block;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		@include text-hidden();
		top: 0;
		z-index: 2;
	}
	&:hover
	{
		.m-bundle-preview__title
		{
			color: $colorPrimary;
		}
	}
}