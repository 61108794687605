@import "../config";

.m-payment-icons {
	@include bg('/images/camc/common/sprite.png', no-repeat, 0 -159px);
	height: 28px;
	margin: 0 auto 15px auto;
	padding: 0;
	@include text-hidden();
	width: 152px;

	@include mq-min($bpTabletLarge) {
		margin-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		margin-bottom: 25px;
	}

	@include mq-min($bpDesktopLarge) {
		margin-bottom: 30px;
	}

	&--tight {
		margin-bottom: 0;
	}
}
