﻿@import "../config";

.m-history-event
{
	margin: 0;
	padding: 0;
	&__inner
	{
		margin: 0;
		padding: 285px 0 15px 0;
		position: relative;
		z-index: 1;
		@include mq-min($bpMobileLarge)
		{
			padding-top: 353px;
		}
		@include mq-min($bpTablet)
		{
			padding-top: 437px;
		}
		@include mq-min($bpTabletLarge)
		{
			padding-top: 409px;
		}
		@include mq-min($bpDesktop)
		{
			padding-top: 555px;
		}
		.js &
		{
			padding-bottom: 0;
			@include mq-min($bpDesktop)
			{
				padding-top: 640px;
			}
		}
	}
	&__image
	{
		height: 270px;
		left: 0;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
		&__image
		{
			display: block;
			height: 270px;
			left: 50%;
			margin: -135px 0 0 -240px;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 480px;
			max-width: none;
			z-index: 1;
			@include mq-min($bpMobileLarge)
			{
				height: 338px;
				margin: -169px 0 0 -300px;
				width: 600px;
			}
			@include mq-min($bpTablet)
			{
				height: 422px;
				margin: -211px 0 0 -375px;
				width: 750px;
			}
			@include mq-min($bpTabletLarge)
			{
				height: 394px;
				margin: -197px 0 0 -350px;
				width: 700px;
			}
			@include mq-min($bpDesktop)
			{
				height: 540px;
				margin: -270px 0 0 -480px;
				width: 960px;
			}
		}
		@include mq-min($bpMobileLarge)
		{
			height: 338px;
		}
		@include mq-min($bpTablet)
		{
			height: 422px;
		}
		@include mq-min($bpTabletLarge)
		{
			height: 394px;
		}
		@include mq-min($bpDesktop)
		{
			height: 540px;
		}
	}
	&__title
	{
		color: $colorPrimary;
		font-family: $fontBold;
		@include font-size($fontSize + 10);
		font-weight: normal;
		@include line-height($fontHeight + 10);
		margin: 0;
		overflow: hidden;
		padding: 0 35px 10px 35px;
		@include text-overflow();
		position: relative;
		z-index: 1;
		@include mq-min($bpMobileLarge)
		{
			left: 35px;
			padding: 0;
			position: absolute;
			text-align: right;
			top: 352px;
			width: 70px;
		}
		@include mq-min($bpTablet)
		{
			@include font-size($fontSize + 20);
			@include line-height($fontHeight + 20);
			top: 434px;
			width: 100px;
		}
		@include mq-min($bpTabletLarge)
		{
			top: 406px;
		}
		@include mq-min($bpDesktop)
		{
			@include font-size($fontSize + 40);
			font-weight: normal;
			@include line-height($fontHeight + 40);
			top: 547px;
			width: 160px;
		}
		.js &
		{
			@include mq-min($bpDesktop)
			{
				top: 632px;
			}
		}
	}
	&__content
	{
		@include min-height(100px);
		margin: 0;
		padding: 0 35px;
		@include mq-min($bpMobileLarge)
		{
			padding-left: 115px;
		}
		@include mq-min($bpTablet)
		{
			padding-left: 145px;
		}
		@include mq-min($bpDesktop)
		{
			padding: 0 40px 0 210px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-right: 45px;
		}
		@include mq-min($bpLarge)
		{
			padding-right: 50px;
		}
	}
}