@import "config";

.cms #ot-sdk-cookie-policy {
  color: $colorBase !important;
  font-family: $fontBase !important;
  font-size: calculateRem($fontSize) !important;
  line-height: calculateRem($fontHeight) !important;

  * {
    color: $colorBase !important;
    font-family: $fontBase !important;
    font-size: calculateRem($fontSize) !important;
    line-height: calculateRem($fontHeight) !important;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700 !important;
    padding-bottom: 10px !important;
    margin-bottom: 0 !important;
  }

  section {
    padding-bottom: 20px !important;
    margin-bottom: 0 !important;
  }

  #ot-sdk-cookie-policy-v2,
  .ot-sdk-container {
    width: 100% !important;
    padding: 0 !important;
  }

  #cookie-policy-title {
    padding-bottom: 10px !important;
    margin: 0 !important;
    font-weight: 700 !important;
  }

  #cookie-policy-description {
    padding-bottom: 10px !important;
    margin: 0 !important;
  }
}
