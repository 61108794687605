﻿@import "../config";

.m-review
{
	margin: 0;
	padding: 0 0 15px 0;
	&__rating
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		@include box-sizing();
		font-family: $fontBase;
		font-family: $fontBold;
		@include font-size($fontSize);
		font-weight: normal;
		@include line-height($fontHeight);
		margin: 0;
		padding: 10px 0 0 0;
	}
	&__description
	{
		@include font-size($fontSize);
		@include line-height($fontHeight);
		margin: 0;
		padding: 5px 0 0 0;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}