﻿@import "../config";

.m-section-block
{
	margin: 0;
	padding: 20px 0;
	text-align: center;
	&__inner
	{
		margin: 0 auto;
		padding: 0;
		max-width: $maxPageWidth;
		&--narrow
		{
			max-width: $maxPageWidth - 200;
		}
	}
	&__title
	{
		color: $colorBase;
		@include font-size($fontSize + 10);
		@include line-height($fontHeight + 10);
		margin: 0;
		padding: 0;
		@include mq-min($bpDesktop)
		{
			@include font-size($fontSize + 14);
			@include line-height($fontHeight + 14);
		}
	}
	&__content
	{
		margin: 0;
		padding: 15px 0 0 0;
		@include mq-min($bpTabletLarge)
		{
			padding-top: 20px;
		}
		@include mq-min($bpDesktop)
		{
			padding-top: 25px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-top: 30px;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 30px 0;
	}
	@include mq-min($bpDesktop)
	{
		padding: 40px 0;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 50px 0;
	}
	&--close
	{
		padding-top: 0;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}